import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import { ParallaxHover } from 'react-parallax-hover';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import Typewriter from 'typewriter-effect';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import { Link } from "gatsby"
import { shuffle } from 'lodash'


import fandom from '../images/about-fandom.png'
import diversity from '../images/about-diversity.png'
import multiFaceted from '../images/about-multi-faceted.png'
import partners from '../images/about-partners.png'
import newMedia from '../images/about-new-media.png'


export default function AboutPage( {data} ) {

    const teamsData = data.webiny.listTeams.data
    // const shuffledTeamsData = shuffle(teamsData);

    // Sort the shuffled array so that the team with entryId "642b1ec65bbda600087fb3eb" is always first
    // shuffledTeamsData.sort((a, b) => {
    //   if (a.entryId === '642b1ec65bbda600087fb3eb') {
    //     return -1;
    //   } else if (b.entryId === '642b1ec65bbda600087fb3eb') {
    //     return 1;
    //   } else {
    //     return 0;
    //   }
    // });
      
    return (
      <div className="bg-black">
        <Seo title="About" />
        <Header />
        <ParallaxProvider>

        <div className="bg-neutral">

          <div className="pt-20 px-10 lg:px-16 lg:pt-28 lg:pb-0 mx-auto">
            <div className="grid grid-cols-1 lg:max-h-[37rem] gap-5 lg:grid-cols-12 lg:h-screen lg:min-h-fit text-orange-100">
              <div className="col-span-1 lg:col-span-5">
                <Parallax translateY={[-50, 10]}>
                  <h2 className="knockoutHTF67 text-6xl xl:text-8xl uppercase lg:tracking-wide text-orange-100 m-0">WE ARE</h2>
                  <div className="px-2 inline-block bg-orange-100">
                    <h2 className="knockoutHTF67 mb-0 text-6xl xl:text-8xl uppercase lg:tracking-wide text-black">
                      <Typewriter
                        options={{
                          autoStart: true,
                          loop: true,
                          delay: 80,
                        }}
                        onInit={(typewriter) => {
                          typewriter.typeString('Storytellers')
                            .pauseFor(2000)
                            .deleteAll()
                          typewriter.typeString('Content creators')
                            .pauseFor(2000)
                            .deleteAll()
                          typewriter.typeString('Creative thinkers')
                            .pauseFor(2000)
                            .deleteAll()
                          typewriter.typeString('Writers')
                            .pauseFor(2000)
                            .deleteAll()
                          typewriter.typeString('Producers')
                            .pauseFor(2000)
                            .deleteAll()
                          typewriter.typeString('Designers')
                            .pauseFor(2000)
                            .deleteAll()
                          typewriter.typeString('Technologists')
                            .pauseFor(2000)
                            .deleteAll()
                            .start();
                        }}
                      />
                    </h2>
                  </div>
                </Parallax>
                  <div className="my-5 lg:mt-12 lg:mb-10">
                    <p className="proximaNova mb-10 text-lg leading-6">We think about how to inspire today's generation and those to come so they may see and hear with pride their culture and experiences embraced on screen. Our work marries the best of Hollywood production with digital behavior, and goes beyond the screen into events and merch.</p>
                    <Link cover to="/careers" className="" bg="#000000">
                      <button className="proximaNova text-yellow-100 border border-solid border-yellow-100 text-md mt-5 lg:mt-0 px-10 py-2 transition duration-500 ease-in-out bg-black hover:bg-yellow-100 hover:text-black hover:border hover:border-solid hover:border-black transform uppercase">Join Us</button>
                    </Link>
                  </div>
              </div>
              <div className="col-span-1 lg:col-span-7 mb-10 lg:mb-0">
              <div className="grid hidden lg:block inline-block lg:grid-cols-2 -ml-22 gap-5 lg:ml-0">
                <div className="m-0 m-auto lg:absolute lg:top-24 lg:right-52 xl:right-80 scale-100 hover:scale-110 transition duration-300 ease-out hover:ease-in hover:z-50">
                <Parallax translateY={[40, -30]}>
                  <ParallaxHover scale width={300} height={343} rotation={9} shadow={6}>
                    <img
                      className=""
                      src={partners}
                      alt=""
                    />
                  </ParallaxHover>
                </Parallax>
                </div>
                <div className="m-0 m-auto lg:absolute lg:top-96 lg:right-64 xl:right-96 scale-100 hover:scale-110 transition duration-300 ease-out hover:ease-in hover:z-50">
                <Parallax translateY={[40, -30]}>
                  <ParallaxHover scale width={300} height={343} rotation={9} shadow={6}>
                      <img
                        className=""
                        src={fandom}
                        alt=""
                      />
                  </ParallaxHover>
                </Parallax>
                </div>
                <div className="m-0 m-auto lg:absolute lg:right-8 xl:right-20 lg:top-40 scale-100 hover:scale-110 transition duration-300 ease-out hover:ease-in hover:z-50">
                <Parallax translateY={[20, -30]}>
                  <ParallaxHover scale width={300} height={343} rotation={8} shadow={4}>
                    <img
                      className=""
                      src={multiFaceted}
                      alt=""
                    />
                  </ParallaxHover>
                </Parallax>
                </div>
                <div className="m-0 m-auto lg:absolute lg:right-12 xl:right-16 lg:top-60 scale-100 hover:scale-110 transition duration-300 ease-out hover:ease-in hover:z-50">
                <Parallax translateY={[70, -30]}>
                  <ParallaxHover scale width={300} height={343} rotation={8} shadow={8}>
                    <img
                      className=""
                      src={newMedia}
                      alt=""
                    />
                  </ParallaxHover>
                </Parallax>
                </div>
                <div className="m-0 m-auto lg:absolute lg:right-60 xl:right-56 lg:top-72 scale-100 hover:scale-110 transition duration-300 ease-out hover:ease-in hover:z-50">
                <Parallax translateY={[30, -10]}>
                  <ParallaxHover scale width={300} height={343} rotation={9} shadow={2}>
                    <img
                      className=""
                      src={diversity}
                      alt=""
                    />
                  </ParallaxHover>
                </Parallax>
                </div>
              </div>
              </div>
            </div>
          </div>
        
        </div>
        
        <Parallax translateY={[5, -10]}>
        <div className="grid block pb-16 lg:hidden">
          <div className="">
            <Splide 
              options={ {
                rewind: true,
                type   : 'loop',
                padding: { left: 20, right: 20 },
                gap: 10,
                perPage: 1,
                pagination: false,
                autoplay: true,
                arrows: false,
              } }
              aria-label="team">
              <SplideSlide>
                <div className="grid grid-cols-1 lg:grid-cols-2">
                    <img
                      className="w-full"
                      src={fandom}
                      alt=""
                    />
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="grid grid-cols-1 lg:grid-cols-2">
                    <img
                      className="w-full"
                      src={diversity}
                      alt=""
                    />
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="grid grid-cols-1 lg:grid-cols-2">
                    <img
                      className="w-full"
                      src={multiFaceted}
                      alt=""
                    />
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="grid grid-cols-1 lg:grid-cols-2">
                    <img
                      className="w-full"
                      src={partners}
                      alt=""
                    />
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
        </Parallax>

        <Parallax translateY={[10, 0]}>
          <div className="bg-orange-100">
            <div className="container mx-auto">
              <div className="py-20 lg:px-16">
                <h2 className="knockoutHTF67 text-5xl lg:text-6xl uppercase tracking-wide text-center mb-20">Our audiences</h2>
                <div className="grid grid-cols-1 grid-rows-2 gap-16">
                  
                  <Parallax translateY={[-20, 10]}>
                    <div className="grid grid-cols-1 text-center lg:text-left lg:grid-cols-4 lg:gap-5">
                      
                      <div className="TitlingGothicFBWide p-5">
                        <h3 className="TitlingGothicFBWide m-0 text-3xl lg:text-4xl">100+</h3>
                        <p className="text-md m-0">Talent in our network</p>
                      </div>

                      <div className="TitlingGothicFBWide p-5">
                        <h3 className="TitlingGothicFBWide m-0 text-3xl lg:text-4xl">185M+</h3>
                        <p className="text-md m-0">Subscribers across our network</p>
                      </div>

                      <div className="TitlingGothicFBWide p-5">
                        <h3 className="TitlingGothicFBWide m-0 text-3xl lg:text-4xl">4B+</h3>
                        <p className="text-md m-0">Monthly views across our network</p>
                      </div>

                      <div className="TitlingGothicFBWide p-5">
                        <h3 className="TitlingGothicFBWide m-0 text-3xl lg:text-4xl">600K+</h3>
                        <p className="text-md m-0">Followers of our own digital IP</p>
                      </div>


                    </div>
                  </Parallax>

                  <Parallax translateY={[-30, 10]}>
                    <div className="grid grid-cols-1 text-center lg:text-left lg:grid-cols-4 lg:gap-5">

                      <div className="TitlingGothicFBWide p-5">
                        <h3 className="TitlingGothicFBWide m-0 text-3xl lg:text-4xl">5M+</h3>
                        <p className="text-md m-0">Books sold worldwide</p>
                      </div>
                    
                      <div className="TitlingGothicFBWide p-5">
                        <h3 className="TitlingGothicFBWide m-0 text-3xl lg:text-4xl">500+</h3>
                        <p className="text-md m-0"> brand campaigns per year</p>
                      </div>

                      <div className="TitlingGothicFBWide p-5">
                        <h3 className="TitlingGothicFBWide m-0 text-3xl lg:text-4xl">68%</h3>
                        <p className="text-md m-0">Minority operated</p>
                      </div>

                      <div className="TitlingGothicFBWide p-5">
                        <h3 className="TitlingGothicFBWide m-0 text-3xl lg:text-4xl">47%</h3>
                        <p className="text-md m-0">Female-identifying workforce</p>
                      </div>

                    </div>
                  </Parallax>

                </div>
              </div>
            </div>
          </div>
        </Parallax>
       
        <Parallax translateY={[10, 0]}>
        <div className="bg-black text-orange-100">
          <div className="container mx-auto">
            <div className="px-10 py-20">
              <h2 className="knockoutHTF67 text-5xl lg:text-6xl uppercase tracking-wide text-center mb-20">Our Capabilities</h2>
              <div className="proximaNova grid grid-cols-1 lg:grid-cols-2 gap-5">
                
                  
                  <div className="">
                    <h3 className="uppercase mb-0 lg:mb-2 text-lg">ACCESS TO TALENT + MEDIA NETWORK</h3>
                    <p className="mb-2 text-sm">Our creator talent network generates over 4B+ monthly views and extends across the entire gaming ecosystem including athletes, celebrities, and actors who game.</p>
                  </div>

                  <div className="">
                    <h3 className="uppercase mb-0 lg:mb-2 text-lg">BRANDED CONTENT, IN VIDEOS + ON STREAM</h3>
                    <p className="mb-2 text-sm">3BD collaborates with brands, media companies, and publishers to authentically integrate brand messaging and products into creator content - both recorded and live streamed.</p>
                  </div>
                  
                  <div className="">
                    <h3 className="uppercase mb-0 lg:mb-2 text-lg">FULL SERVICE CONTENT PRODUCTION AND DISTRIBUTION</h3>
                    <p className="mb-2 text-sm">3BD produces everything from large-scale esports tournaments to experiential activations. We do white label content at scale and custom programming.</p>
                  </div>
                  
                  <div className="">
                    <h3 className="uppercase mb-0 lg:mb-2 text-lg">PREMIUM IP CONTENT</h3>
                    <p className="mb-2 text-sm">3BD is the creative force behind box office hit QUEEN & SLIM and I AM NUMBER 4. We have  9 shows in production or development at Netflix, Hulu, Sony, HBO Max, and Snap.</p>
                  </div>

                  <div className="">
                    <h3 className="uppercase mb-0 lg:mb-2 text-lg">OWN DIGITAL FRANCHISES</h3>
                    <p className="mb-2 text-sm">Our original, digital brands are rooted in gaming culture and driven by content creator partnerships. Each appeals to a distinct audience and lives across social platforms as distributed IP.</p>
                  </div>


              </div>
            </div>
          </div>
        </div>
        </Parallax>

        <div className="bg-neutral-900 text-orange-100">
          <div className="container mx-auto px-10 py-20">
            <h2 className="knockoutHTF67 text-5xl lg:text-6xl uppercase tracking-wide text-center mb-20">Team</h2>
            <div className="lg:mt-12">
              <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
                {teamsData.map(team => {
                  return (team.visibility === true) ? 
                    <div key={team.entryId}>
                      <img src={team.image} />
                      <div className="mt-4">
                        <p className="TitlingGothicFBWide text-sm uppercase lg:text-md m-0">
                          {team.name}
                        </p>
                        <p className="text-xs">{team.position}</p>
                      </div>
                    </div>
                    :
                    null
                  })}
              </div>
              <Link cover to="/careers" className="grid grid-cols-1 mt-14" bg="#000000">
                <button className="mx-auto uppercase text-orange-100 border border-solid border-orange-100 text-xs mt-5 lg:mt-0 px-10 py-2 transition duration-500 ease-in-out bg-black hover:bg-orange-100 hover:text-black hover:border hover:border-solid hover:border-black">Join Us</button>
              </Link>
            </div>
          </div>
        </div>

        </ParallaxProvider>
        <Footer />
      </div>
    )
}

export const pageQuery = graphql
`
  query TeamPosts {
    webiny {
      listTeams(sort: createdOn_DESC, limit: 100) {
        data {
          image
          id
          entryId
          department
          createdOn
          link
          modelId
          name
          position
          visibility
        }
      }
    }
  }
`
