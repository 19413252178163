import React, { Component } from "react"
import { Link } from "gatsby"
import Seo from "../../components/seo"

import Header from "../../components/header"

class BrandIntegrationsPage extends Component {
  
  render() {
    return (
      <div>
        <Header />
        <Seo title="Brand Integrations" />
        <div className="container-fluid brandintegrations">
          <div className="row">
            <div className="col-12 brandintegrationsblock ">
              <h2>
                BRAND<br></br>INTEGRATIONS
              </h2>
              <p>We'll vouch for you.</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10 col-md-3 section-brandintegrations-one">
              <h3>This is Influence</h3>
              <p>
                We’ll vouch for you, promoting your brand to a viewership of 600
                million monthly engagements. We let them know you’re cool. And
                once you’re in, you’re in. That’s influence.
              </p>
              <h3>Start with ‘Why’</h3>
              <p>
                Tell us why audiences want what you have. Then, we’ll work with
                our influencers to develop compelling campaigns and get the word
                out.
              </p>
              <p>
                What does that look like? Send an influencer on a branded
                experience, fly a vlogger out to cover your media event, or even
                bring creators to your home office. The options are endless but
                we promise measurable results: millions of views, amplified
                awareness, and more customers.
              </p>
            </div>
            <div className="col-10 col-md-3 offset-md-1 section-brandintegrations-two">
              <h3>Get Started</h3>
              <p>
                Your brand adds real value to people’s lives. Let’s share that
                with our audiences and you’ll have dedicated, lifelong
                customers.
              </p>
              <p>
                Get in touch so we can start strategizing on content and
                exploring the audiences that will take our brand’s reach to an
                entirely new level.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 section-brandintegrations-related">
              <h4>RELATED PROJECTS</h4>
            </div>
          </div>
          <div className="row section-brandintegrations-related-projects">
            <div className="col-6 col-md-3 project playstation">
              <Link to="/projects/playstation">
                <h4>PLAYSTATION</h4>
              </Link>
            </div>
            <div className="col-6 col-md-3 project riot">
              <Link to="/projects/riot">
                <h4>
                  RIOT<br></br>LEAGUE OF LEGENDS<br></br>NEW PLAYERS<br></br>
                  CAMPAIGN
                </h4>
              </Link>
            </div>
            <div className="col-6 col-md-3 project childsplay">
              <Link to="/projects/orion-childs-play">
                <h4>CHILD’S PLAY</h4>
              </Link>
            </div>
            <div className="col-6 col-md-3 project misbits">
              <Link to="/projects/misbits">
                <h4>MISBITS</h4>
              </Link>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
}

export default BrandIntegrationsPage
