import React, { Component } from "react"
import { Link } from "gatsby"
import Seo from "../../components/seo"

import Header from "../../components/header"

class ClownPage extends Component {
 
  render() {
    return (
      <div>
        <Header />
        <Seo title="Clownpocalypse" />
        <div className="container-fluid clownpocalypse">
          <div className="row">
            <div className="col-12 clownpocalypseblock ">
              <h2>CLOWNPOCALYPSE</h2>
              <p>Get ready to go viral.</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10 col-md-6 section-clownpocalypse-one">
              <p>
                At 3BLACKDOT, we love collaborating with visionary minds. So when we were given the opportunity to work
                with famed horror legend, Eli Roth (Hostel, Cabin Fever, Death Wish), we jumped. For this project, we
                wanted to go beyond producing a traditional blockbuster film—we collaborated to create a new, immersive
                world of horror—CLOWNPOCALYPSE.
              </p>
              <p>
                Together with Roth and screenwriter Philip Gelatt (Netflix’s Love, Death & Robots), we’re developing a
                holistic world around CLOWNPOCALYPSE. Fans will consume the world through a fully realized, narrative
                universe capable sustaining terror beyond just the screen. Immersive, terrifying, and fun, it includes:
                a feature film, AR videogame, live event, short-form digital content, and merchandise. For the seasoned
                horror fan looking for their next thrill, this is a nightmare come true. Our approach is to build worlds
                that meet the needs of the current cultural norms. CLOWNPOCALYPSE embodies the fluidity of our
                experiences from online to offline, from gaming to movies and experiential events, all under the
                umbrella of a single narrative, giving fans a wholly satisfying experience.
              </p>
              <p>CLOWNPOCALYPSE, the feature film, is slated for production in the first quarter of 2020.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 section-clownpocalypse-services">
              <img
                className="img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset-Services.png"
                alt=""
              />
              <Link to="/services/content-production">
                <h4>CONTENT ORIGINAL</h4>
              </Link>
              <Link to="/services/live-events">
                <h4>LIVE EVENTS</h4>
              </Link>
              <Link to="/services/merchandise">
                <h4>MERCHANDISE</h4>
              </Link>
              <Link to="/services/game-development">
                <h4>GAME DEVELOPMENT</h4>
              </Link>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
}

export default ClownPage
