import React, { Component } from "react"
import { Link } from "gatsby"
import Seo from "../../components/seo"

import Header from "../../components/header"
import Footer from "../../components/footer"

class MerchandisePage extends Component {

  render() {
    let isHeaderBgBlack = false;
    const canonicalUrl = this.props.location.pathname;
    if (canonicalUrl === "/services/merchandise/") {
      isHeaderBgBlack = true;
    } 
    return (
      <div>
        <Header isHeaderBgBlack={isHeaderBgBlack} />
        <Seo title="Merchandise" />
          <div className="bg-yellow-100 proximaNova p-10 lg:px-16 lg:py-20 text-black">
          <div className="my-10">
            <div className="">
              <h2 className="knockoutHTF67 px-1 lg:px-3 inline-block bg-black m-0 text-5xl lg:text-6xl uppercase text-yellow-100 lg:tracking-wide mb-5">MERCHANDISE</h2>
              <p>
                We are the bridge between Influencer Merchandise & their
                Audience.
              </p>
            </div>
            <div className="my-10">
              <h3 className="mb-7 knockoutHTF47 tracking-wide text-4xl lg:text-5xl uppercase mt-0 mb-0 mt-3">Merch Has Power</h3>
              <p>
                The best brands exist across platforms. To really engage your
                audience, you’ve got to bridge the divide between their digital
                and physical lives.
              </p>
              <p>
                Merchandise creates a tangible, tactile link between your
                content and the real world, a link that builds lifetime
                viewership, loyalty, and value.
              </p>
              <h3 className="mb-7 knockoutHTF47 tracking-wide text-4xl lg:text-5xl uppercase mt-0 mb-0 mt-3">How We Do Products</h3>
              <p>
                We’re here to build brands, not one-offs. So we’ll design
                merchandise that represents everything you are and everything
                your fans love. The possibilities are endless but the goal is
                clear: create, market, sell and ship physical product your
                audience will obsess over.
              </p>
              <h3 className="mb-7 knockoutHTF47 tracking-wide text-4xl lg:text-5xl uppercase mt-0 mb-0 mt-3">Things We Make</h3>
              <p>
                Sky’s the limit, but here are some of the things we’ve made to
                date:
              </p>
              <ul>
                <li>Apparel</li>
                <li>Posters</li>
                <li>Plushies</li>
                <li>Vinyl Toys</li>
                <li>Custom Cut & Sewn Garments</li>
                <li>Screen Printing</li>
                <li>Branding</li>
                <li>Photography</li>
              </ul>
            </div>
            <div className="">
              <h3 className="mb-7 knockoutHTF47 tracking-wide text-4xl lg:text-5xl uppercase mt-0 mb-0 mt-3">Our Process</h3>
              <p>
                Starts with a chat: we explore who you are and what your
                audience would love to purchase. Then we work side by side,
                developing concepts, until we’ve settled on the right products.
                From there, it’s hands off for you while we design, sample, and
                manufacture your gear.
              </p>
              <h3 className="mb-7 knockoutHTF47 tracking-wide text-4xl lg:text-5xl uppercase mt-0 mb-0 mt-3">360° Support</h3>
              <p>
                Don’t worry, we’d never leave you hanging with a garage full of
                sweatshirts. In fact, we handle fulfillment in-house so you’ll
                never touch a tape roll.
              </p>
              <p>
                And we don’t stop there: successful merch needs pre-launch buzz,
                engaging marketing, high-quality photography, first-class web
                development, and a coordinated team to put it all together. We
                are that team.
              </p>
              <p>
                From customer acquisition to service, we’ll manage all the
                nuances of selling and shipping your new products. You just be
                you, and the sales will roll in.
              </p>
              <h3 className="mb-7 knockoutHTF47 tracking-wide text-4xl lg:text-5xl uppercase mt-0 mb-0 mt-3">Get Started</h3>
              <p>
                Bridge the divide. Connect their digital and physical worlds and
                you’ll grow your core audience of insanely dedicated super fans.
              </p>
              <p>
                Get in touch now so we can start sketching concepts right away.
              </p>
            </div>
          </div>
          <div className="">
            <div className="col-12 section-merchandise-related">
              <h4>RELATED PROJECTS</h4>
            </div>
          </div>
          <div className="">
            <div className="col-6 col-md-3 project alphabetas">
              <Link to="/projects/alpha-betas">
                <h4>Alpha Betas</h4>
              </Link>
            </div>
            <div className="col-6 col-md-3 project vanoss">
              <Link to="/projects/vanoss">
                <h4>Vanoss</h4>
              </Link>
            </div>
            <div className="col-6 col-md-3 project laurenzside">
              <Link to="/projects/lauren-z-side">
                <h4>Lauren Z Side</h4>
              </Link>
            </div>
            <div className="col-6 col-md-3 project softserve">
              <Link to="/projects/soft-serve">
                <h4>SoftServe</h4>
              </Link>
            </div>
          </div>
        </div>
        <Footer /> 
      </div>
    )
  }
}

export default MerchandisePage
