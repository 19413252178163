import React, { Component } from "react"
import { Link } from "gatsby"
import Seo from "../../components/seo"

import Header from "../../components/header"

class SoftServePage extends Component {
 
  render() {
    return (
      <div>
        <Header />
        <Seo title="SOFT SERVE" />
        <div className="container-fluid softserve">
          <div className="row">
            <div className="col-12 softserveblock ">
              <h2>
                SOFT
                <br></br>SERVE
              </h2>
              <p>Indulge. Share. Collect.</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10 col-md-6 section-softserve-one">
              <p>
                At 3BLACKDOT, we exist within the intersection of gaming, lifestyle, and culture. With the launch of our
                new vinyl toy brand, Soft Serve, we’re bringing a new, shareable experience in the form of fun, premium
                collectibles.
              </p>
              <p>
                The driving force behind Soft Serve is a vivid, wholesome concept: A 20-something-year-old buys an old
                ice cream truck with aspirations of selling something new and fun. They are a gamer, they love ice
                cream, and they love collecting vinyl toys. The ice cream truck is remodeled as a mobile haven for their
                collection of toys, games, and favorite flavors of ice cream. They drive around to conventions, food
                truck streets, and their friends’ neighborhood to show off their truck and shiny new collectibles.
              </p>
              <p>
                Soft Serve is more than a line of toys; it’s an interactive experience and its own slice of culture.
                Modeled after beloved influencers like Vanoss and Lauren Z Side, each toy comes with its own custom
                display case and trading cards, for extra collectability.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 section-softserve-services">
              <img
                className="img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset-Services.png"
                alt=""
              />
              <Link to="/services/merchandise/">
                <h4>MERCHANDISE</h4>
              </Link>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
}

export default SoftServePage
