import React, { Component } from "react"
import { Link } from "gatsby"
import Seo from "../../components/seo"

import Header from "../../components/header"
class DigitalStudioPage extends Component {
 
  render() {
    return (
      <div>
        <Header />
        <Seo title="Digital Studio" />
        <div className="container-fluid digitalstudio">
          <div className="row">
            <div className="col-12 digitalstudioblock ">
              <h2>
                DIGITAL<br></br>STUDIO
              </h2>
              <p>Film here in LA.</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10 col-md-3 section-digitalstudio-one">
              <h3>A Small Space for Big Ideas</h3>
              <p>
                Know where you’ll shoot your next big idea? If the only thing
                standing between you and that Streamy is the shoot location,
                3BLACKDOT’s Digital Studio is a conveniently located LA
                recording space open to anyone.
              </p>
              <p>
                Our small but versatile studio provides a variety of set pieces
                for live streaming, mixed reality, magazine shows, green
                screening, or scripted content.
              </p>
              <h3>Available Equipment</h3>
              <ul>
                <li>Live Streaming Hardware</li>
                <li>Multiplayer eSports</li>
                <li>Studio Show Desk</li>
                <li>Editing & Audio Suites</li>
                <li>VO Booth</li>
                <li>Set Flats</li>
                <li>Green Screen Stage</li>
                <li>Tech & Production</li>
              </ul>
            </div>
            <div className="col-10 offset-md-1 col-md-3 section-digitalstudio-two">
              <h3>Get Started</h3>
              <p>
                Call the writers and order craft services because you’ve found
                your location. Contact us today and we’ll put your project on
                the schedule. Yup, that big idea of yours is about to be
                reality.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 section-digitalstudio-related">
              <h4>RELATED PROJECTS</h4>
            </div>
          </div>
          <div className="row section-digitalstudio-related-projects">
            <div className="col-6 col-md-3 project playstation">
              <Link to="/projects/playstation">
                <h4>PLAYSTATION</h4>
              </Link>
            </div>
            <div className="col-6 col-md-3 project misbits">
              <Link to="/projects/misbits">
                <h4>MISBITS</h4>
              </Link>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
}

export default DigitalStudioPage
