import React, { Component } from "react"
import { Link } from "gatsby"
import Seo from "../../components/seo"

import Header from "../../components/header"

class RiotPage extends Component {
 
  render() {
    return (
      <div>
        <Header />
        <Seo title="RIOT LEAGUE OF LEGENDS NEW PLAYERS CAMPAIGN" />
        <div className="container-fluid riot">
          <div className="row">
            <div className="col-12 riotblock ">
              <h2>
                RIOT<br></br>LEAGUE OF LEGENDS<br></br>NEW PLAYERS<br></br>
                CAMPAIGN
              </h2>
              <p>A global campaign driving 16 million views.</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10 col-md-6 section-riot-one">
              <p>
                Imagine you’re Riot Games, the creator of League of Legends, one of the most successful games in the
                world. You’re looking to introduce your game to new players all of whom are facing a steep level-up
                curve—who do you turn to? When Riot came to us, we jumped at this challenge: to bring in a whole new
                generation of fans and show them how supportive and welcoming the League of Legends community could be.
              </p>
              <p>
                Together with Riot, 3BLACKDOT set out to create a global campaign for League of Legends that would span
                across 6 different countries and its 100+ million player base. To combat the game’s reputation as a
                challenging entry, our online strategy utilized a ‘pay it forward’ theme. It centered around teaming up
                popular non-competitive gaming influencers with seasoned LoL players to demonstrate how fun learning and
                playing together could be. The dedicated online video content from this mentorship program generated
                over 16M views and lead to excitement across influencer channels.
              </p>
              <p>
                The result: our campaign was enormously successful, driving 16 million views, over 300 event attendees,
                and over 2 million redeemed in-game icons.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 section-riot-services">
              <img
                className="img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset-Services.png"
                alt=""
              />
              <Link to="/services/brand-integrations/">
                <h4>BRANDED INTEGRATIONS</h4>
              </Link>
              <Link to="/services/live-events">
                <h4>LIVE EVENTS</h4>
              </Link>
              <Link to="/services/content-production">
                <h4>CONTENT ORIGINAL</h4>
              </Link>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
}

export default RiotPage
