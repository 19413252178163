import React, { Component } from "react"
import { Link } from "gatsby"
import Seo from "../../components/seo"

import Header from "../../components/header"

class MisbitsPage extends Component {
 
  render() {
    return (
      <div>
        <Header />
        <Seo title="MISBITS" />
        <div className="container-fluid misbits">
          <div className="row">
            <div className="col-12 misbitsblock ">
              <h2>MISBITS</h2>
              <p>Heads will roll.</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10 col-md-6 section-misbits-one">
              <p>
                As a game that’s just as fun to watch as it is to play, MisBits
                embodies the core tenants of game development for 3BLACKDOT. We
                believe great games build great communities and creating space
                for individual discovery is integral to that process.
              </p>
              <p>
                Our upcoming game, MisBits, accomplishes just that by being the
                most fun and exciting house on the block. As a multiplayer, 3D
                digital sandbox, the game itself is a space for exploration and
                curiosity, allowing the player to unlock new content through
                experimentation and creative expression. MisBits rewards
                curiosity and encourages experimentation. The possibilities are
                endless, only limited by imagination.
              </p>
              <p>
                And because it’s multiplayer, that means you can share and
                experience the madness together with friends. Join in on the fun
                and find other players to make mischief with, or hack it on your
                own! It’s misfit for misfit in this crazy, colorful world of
                building blocks and rolling heads.
              </p>
              <p>
                MisBits launches in the first quarter of 2020—heads will roll!
              </p>
              <p>Website - 
                <a
                  href="https://misbits.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white"
                > MisBits.com</a>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 section-misbits-services">
              <img
                className="img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset-Services.png"
                alt=""
              />
              <Link to="/services/game-development/">
                <h4>GAME DEVELOPMENT</h4>
              </Link>
              <Link to="/services/merchandise/">
                <h4>MERCHANDISE</h4>
              </Link>
              <Link to="/services/brand-integrations/">
                <h4>BRAND INTEGRATIONS</h4>
              </Link>
              <Link to="/services/digital-studio/">
                <h4>DIGITAL STUDIO</h4>
              </Link>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
}

export default MisbitsPage
