import * as React from "react"
import { graphql } from "gatsby"
import { RichTextRenderer } from '../components/rich-text-renderer'
import { Helmet } from 'react-helmet'
import Header from "../components/header"
import Footer from "../components/footer"

import Layout from "../components/layout"
import Seo from "../components/seo"


const BookPostTemplate = ({ data, location }) => {
  const post = data.webiny.getBooks.data
  const siteTitle = data.site.siteMetadata?.title || `Title`

  

  return (
    <Layout location={location} title={siteTitle}>
    <Seo 
        title={post.title}
        description={post.description || post.excerpt} />
    <Helmet bodyAttributes={{ className: 'books-page' }} />
    <Header />
    <div className="bg-amber-100">
      <div className="pt-16" />

      <div className="container mx-auto">
        <div className="inline-block w-full">
            <img src={post.bookCover} className="w-80" />
            <br />
            <h1 className="text-3xl font-bold uppercase mb-0">{post.title}</h1>
            <p>Book by <strong>{post.author.authorName}</strong></p>
            <p><span>Publisher: {post.publisher}</span></p>
            {post.about ? 
            <div itemProp="articleBody" className="text-sm">
            <RichTextRenderer content={post.about} />
            </div>
            : null }
        </div>
      </div>

    </div>
    <Footer />
  </Layout>


  )
}


export default BookPostTemplate

export const pageQuery = graphql`
  query PostBySlug(
      $slug: String
    ) {
    site {
      siteMetadata {
        title
      }
    }
    webiny {
        getBooks(where: {slug: $slug}) {
            data {
                title
                slug
                id
                entryId
                bookCover
                bookEdition
                bookFormat
                createdOn
                audience
                abstract
                about
                modelId
                numberOfPages
                publisher
                illustrator
                isbn
                author {
                  authorName
                  authorBio
                  id
                }
            }
        }
    }
  }
`
