import React, { Component } from "react"
import { Link } from "gatsby"
import InstagramEmbed from "react-instagram-embed"
import Seo from "../../components/seo"

import Header from "../../components/header"

class VanossPage extends Component {
  render() {
    return (
      <div>
        <Header />
        <Seo title="VANOSS" />
        <div className="container-fluid vanoss">
          <div className="row">
            <div className="col-12 vanossblock ">
              <h2>Vanoss</h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10 col-md-6 section-vanoss-one">
              <p>
                VanossGaming (Evan Fong) is one of the most influential gaming
                content creators in the world with over 25 million subscribers
                and over 100 million monthly views. Since our founding days,
                VANOSS has been a longtime partner of 3BD in social media
                management, merchandise and brand partnerships.
              </p>
              <p>
                VANOSS’ social media channels (Twitter, Instagram and Facebook)
                of over 9 million engaged followers are managed by 3BD’s social
                team. From social media ideation to producing fully illustrated
                8-tile posts, we collaborate with VANOSS to create original art
                that is relatable and unique to the VanossGaming community.
              </p>
              <p>
                VANOSS’ iconic owl logo does not live only in the digital space.
                3BD’s merchandise team has fully developed a lifestyle brand
                that is highly recognized in the gaming community and worn by
                millions of fans. Every step of that process, from ideation to
                design, to manufacturing, promotion, and fulfillment, is
                executed in-house by our team to connect directly to VANOSS’
                followers.
              </p>
              <p>
                <InstagramEmbed
                  url="https://www.instagram.com/p/B0cHwnfgfp8/"
                  maxWidth={550}
                  hideCaption={true}
                  containerTagName="blockquote"
                  protocol=""
                  injectScript
                  onLoading={() => {}}
                  onSuccess={() => {}}
                  onAfterRender={() => {}}
                  onFailure={() => {}}
                />
              </p>
              <p>
                <InstagramEmbed
                  url="https://www.instagram.com/p/CAqvGB0pleq/"
                  maxWidth={550}
                  hideCaption={true}
                  containerTagName="blockquote"
                  protocol=""
                  injectScript
                  onLoading={() => {}}
                  onSuccess={() => {}}
                  onAfterRender={() => {}}
                  onFailure={() => {}}
                />
              </p>
              <img
                className="img-fluid mx-auto d-block best-gaming"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/best-in-gaming.png"
                alt=""
              />
              <img
                className="img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/shorty-logo.png"
                alt=""
              />
            </div>
          </div>
          <div className="row justify-content-center no-gutters">
            <div className="col-12 col-md-6">
              <img
                className="img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset144.jpg"
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset145.jpg"
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset146.jpg"
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset147.jpg"
                alt=""
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 section-vanoss-services">
              <img
                className="img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset-Services.png"
                alt=""
              />
              <Link to="/services/merchandise/">
                <h4>MERCHANDISE</h4>
              </Link>
              <Link to="/services/content-production">
                <h4>CONTENT PRODUCTION</h4>
              </Link>
              <Link to="/services/publishing/">
                <h4>PUBLISHING</h4>
              </Link>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
}

export default VanossPage
