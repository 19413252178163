import React, { Component } from "react"
import { Link } from "gatsby"
import Seo from "../../components/seo"

import Header from "../../components/header"

class PlaystationPage extends Component {

  render() {
    return (
      <div>
        <Header />
        <Seo title="PLAYSTATION" />
        <div className="container-fluid playstation">
          <div className="row">
            <div className="col-12 playstationblock ">
              <h2>PLAYSTATION</h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10 col-md-6 section-playstation-one">
              <p>
                <strong>3BD Studios</strong>
                <br />
                We bridge the gap between hardcore and casual gamers alike, by
                creating a vibrant content ecosystem which champions the player
                community. We provide superior production value, creative
                concepts, premium graphics packages and an authentic stylized
                look across all of our live esports productions.
              </p>
              <p>
                <strong>PlayStation Content</strong>
                <br />
                Play. Watch. Learn.
                <br />
                <br />
                We are developing and producing content for the Playstation
                Competition Center, a web and mobile destination where millions
                across the world visit to elevate their competitive play by
                participating in tournaments and to find competitive gaming
                content, curated based on fans’ favorite titles. The Competition
                Center is the one place that you can go to view exclusive
                content, learn tips and tricks from the top gamers in the world,
                and gain access to the biggest esports events. It provides
                exclusive access to PS4 Tournaments by recommending online
                competitions that you can join.
              </p>
              <div className="embed-responsive embed-responsive-16by9">
                <video width="100%" controls>
                  <source
                    src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/SIZZLE_CUT_v5.mp4#t=2"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 section-playstation-services">
              <img
                className="img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset-Services.png"
                alt=""
              />
              <Link to="/services/brand-integrations/">
                <h4>BRANDED INTEGRATIONS</h4>
              </Link>
              <Link to="/services/content-production">
                <h4>CONTENT PRODUCTION</h4>
              </Link>
              <Link to="/services/digital-studio/">
                <h4>DIGITAL STUDIO</h4>
              </Link>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
}

export default PlaystationPage
