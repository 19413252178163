import React, { Component } from "react"
import Seo from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer";


class BlogPage extends Component {
  render() {
    let isHeaderBgBlack = false;
    const canonicalUrl = this.props.location.pathname.replace(/[^\w\s]/gi, "");

    if (canonicalUrl === "blog") {
      isHeaderBgBlack = true;
    } 
    return (
      <div>
      <Header isHeaderBgBlack={isHeaderBgBlack} />
        <Seo title="3BLACKDOT BLOG" />
          <div className="bg-yellow-100 p-10 lg:px-16 lg:py-20 text-black">
            <div className="my-10">
              <h3 className="mb-7 knockoutHTF47 uppercase text-5x lg:text-6xl">3BD's ANTI-RACISM STATEMENT</h3>
              <p className="date">June 12, 2020</p>
              <p>
                Either we see in each other brothers, or we live in a world of
                strangers. I believe that there are no strangers in God’s
                creation. There are no strangers in a world that becomes smaller
                and smaller. Today I know right away when something happens,
                whatever happens, anywhere in the world. So there is no excuse
                for us not to be involved in these problems. A century ago, by
                the time the news of a war reached another place, the war was
                over. Now people die and the pictures of their dying are offered
                to you and to me while we are having dinner. Since I know, how
                can I not transform that knowledge into responsibility?
                <br />
                <br />
                <span className="attribution">
                  --Elie Wiesel, Writer, Political Activist, Nobel Laureate,
                  Holocaust survivor.
                </span>
              </p>
              <br />
              <br />
              <p>
                The murder of George Floyd and so many others should disgust all
                Americans who believe in the rule of law and our system of
                justice. We do not have a system of judge, juror and executioner
                in the form of the police. It does not matter if someone is
                accused of speeding or murder, if it is forging or securities
                fraud. You are innocent until proven guilty. Lawlessness by
                those sworn to ‘serve and protect’ is a grave threat to our
                society. The police cannot be allowed to bypass due process and
                decide to whom the constitution and the right to equal
                protection under the law applies. The persistent militarization
                of the police against Black Americans is not American. It’s
                tyranny.
              </p>
              <p>
                When civil servants, funded by our tax dollars, become an
                oppressive force in our community - it’s tyranny. When the
                president threatens to send our own military - our own families
                serving this country - to ‘dominate’ civil protest - it’s
                tyranny. Tyranny feels like a dated term in America, reserved
                for describing life in Colonial America in the 1700s. The
                foundation of the American mythology is our pride in taking up
                arms against tyranny.
              </p>
              <p>
                As a society, what we do in the moment shapes the future. George
                Floyd’s execution was not decided just by 4 rogue officers
                taking the law into their own hands in the 8:46 in which he was
                pinned to the ground. The environment where police officers felt
                those actions to be morally acceptable exists when we, as a
                society, have accepted expediency and convenience over integrity
                time and time again. It was perpetuated when we, as citizens,
                selectively applied our values and did not apply pressure for
                meaningful change in the many examples of past police brutality.
                When we stopped listening to the voices of the marginalized.
                When we forgot that it is only, unfortunately, through their
                struggle, blood and sacrifice that we have been afforded the
                luxury of a quiet conscience. While those officers should be
                held accountable for his murder is necessary -- it is far from
                being enough.
              </p>
              <p>
                We, as a society, are all responsible for the environment that
                allowed those officers to feel comfortable in making those
                decisions.
              </p>
              <p>
                So how will we distinguish this moment, second, minute, hour or
                day from the next so that we transform these singular moments
                into movements that impact our world? It starts with declaring
                that we stand with the marginalized. We will not take for
                granted the need for enforcement of our collective ideas and we
                will work to advance those ideas in our society.
              </p>
              <p>
                Our society is at its best not when we delude ourselves into
                thinking our system is perfect, but when we hold ourselves
                accountable to invest in fixing the imperfections. The
                collective guilt and discomfort we are all feeling is
                instructive. It's a reminder that freedom from oppression for
                the marginalized is true freedom for everyone.
              </p>
              <p>
                From this day forward, 3BD will apply our mantra of moments to
                movements to doing our part to actively work toward the society
                we wish to belong to.
              </p>
              <p>The 3 focus areas will be:</p>
              <p>
                First, It starts from within: Culture drives all human behavior.
                We are aggressively anti-racism, discrimination, bigotry and
                hatred. We will do our part to make sure we are exercising a
                cultural exchange internally - there is a demographic diversity
                among our ranks that we will leverage to create moments of
                understanding, compassion and clarity. Coming to a better
                understanding of each other and carrying this understanding into
                our respective communities drives real difference.
              </p>
              <p>
                Second, Voter education and reform: We recognize that we are not
                the experts and will create a fact based, non-partisan program
                that informs and educates us on voter rights, registration and
                ways to stay civically engaged. We will form alliances with
                individuals and organizations in the field with the goal of
                informing policies and actively participating in elections
                moving forward. Change happens at the polls and beyond.
              </p>
              <p>
                Thirdly, Supporting voices: We will continue to use our platform
                and organization to support voices both internally and amongst
                our network of talented creators to produce art which aligns
                with our stance and gives more prominence to marginalized and
                underrepresented voices. Projects such as QUEEN AND SLIM and
                PARTY CHAT are an important part of the 3BD DNA and will
                continue to be so.
              </p>
              <p>
                In each of these areas we still have much to learn on how we, as
                3BD can best effect change.
              </p>
              <p>
                In this moment, we declare quite simply a responsibility to no
                longer stay silent about things we do not accept. Starting now.
              </p>
              <p>3BD</p>
              <br />
              <br />
              <hr className="border-black" />
            </div>
            <div className="mb-5">
              <h3 className="mb-7 knockoutHTF47 uppercase text-5x lg:text-6xl">CREATE. COLLABORATE. INNOVATE</h3>
              <p className="date">June 12, 2020</p>
              <p className="sub-text">
                Here's what we're all about as a company. Let's work together.
              </p>
              <div class="embed-responsive embed-responsive-16by9 videoPost">
                <iframe
                  class="embed-responsive-item"
                  title="We Are 3BLACKDOT"
                  src="https://www.youtube.com/embed/xyJ3owQnpYo?rel=0"
                  allowfullscreen
                ></iframe>
              </div>
              <br />
              <br />
              <hr className="border-black" />
            </div>
            <div className="mb-5">
              <img
                className="img-fluid mx-auto d-block "
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset59.png"
                alt=""
              />
            </div>
            <div className="mb-5">
              <img
                className="img-fluid mx-auto d-block "
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset38.png"
                alt=""
              />
            </div>
            <div className="mb-5">
              <img
                className="img-fluid mx-auto d-block "
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset37.png"
                alt=""
              />
            </div>
            <div className="mb-5">
              <img
                className="img-fluid mx-auto d-block "
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset40.png"
                alt=""
              />
            </div>
            <div className="mb-5">
              <img
                className="img-fluid mx-auto d-block "
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset36.png"
                alt=""
              />
            </div>
          </div>
        <Footer />
      </div>
    );
  }
}

export default BlogPage;