import * as React from "react"
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '../../node_modules/@splidejs/react-splide/dist/css/splide.min.css'

const CarouselSlider = ({ carousel, slidesPerPage, type }) => {

    if (carousel.length === 0) {
        return <p>No items were found.</p>
    }
  
    const generateUrl = (item) => {
        if (type === 'booksData') {
            return `https://www.3blackdot.com/publishing/${item.slug}`
        }
    }

    const options = {
        rewind: true,
        pagination: false,
        width : '100%',
        perPage: slidesPerPage,
        gap   : '5rem',
        drag   : 'free',
        breakpoints: {
          1024: {
            perPage: 2,
          },
          768: {
            perPage: 1,
            snap: false,
          },
        },
    }

  return (
    <Splide 
      options={options}
      className="CarouselSlider">
      {carousel.filter(item => item.visibility === true && item.featured === true).map(item => (
        <SplideSlide key={item.entryId} className="py-2 max-w-xs">
                <div 
                    key={item.slug} 
                    className="mb-5 lg:mb-0 mt-auto"
                >
                    <button key={item.id}>
                    <div 
                        className="rounded-lg bg-cover"
                    >
                        <img 
                        src={item.bookCover} 
                        className="mx-auto max-w-xs shadow-lg rounded-lg hover:scale-105 ease-in-out duration-500" 
                        />
                    </div>
                    </button>
                    <div className="my-3 max-w-xs">
                      <h2 className="w-full knockoutHTF67 text-4xl antialiased leading-8 uppercase mb-2">{item.title}</h2>
                      <div className="text-sm mb-3">Book by {item.author.authorName}</div>
                      <p className="text-xs">{item.abstract}</p>
                    </div>
                </div>
        </SplideSlide>
      ))}
    </Splide>
  )
}

export default CarouselSlider