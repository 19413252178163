import React, { Component } from "react"
import { Link } from "gatsby"
import { TwitterTweetEmbed } from "react-twitter-embed"
import InstagramEmbed from "react-instagram-embed"
import Seo from "../../components/seo"

import Header from "../../components/header"
import ABLogo from "../../images/alpha-betas-logo.png"

class AlphaBetasPage extends Component {

  render() {
    return (
      <div>
        <Header />
        <Seo title="Alpha Betas" />
        <div className="container-fluid alphabetas">
          <div className="row">
            <div className="col-12 alphabetasblock">
              <div class="hero-logo">
                <a
                href="https://alphabetas.3blackdot.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="logo"
                  src={ABLogo}
                  alt="Alpha Betas Logo"
                />
                </a>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10 col-md-6 section-alphabetas-one">
              <p>
                In <b><i>Alpha Betas</i></b>, video games are powering the world thanks to a massive, top-secret CIA program. In the comedic style of Rick & Morty meets Westworld, the show follows an elite virtual strike force of four top gamers as they drop into the virtual realms of video games to fix potentially world-ending issues. Known as the Alpha Team, these four willfully reckless and dangerously arrogant guys are the tip of a five-hundred billion dollar US Government spear sent to be heroes in high-octane pixelated worlds.
              </p>
              <p>The pilot episode will premiere on <a href="https://www.youtube.com/user/VanossGaming" target="_blank">VanossGaming’s YouTube</a> channel on March 13, 2021.  Check out the trailer below.</p>
              <p>Alpha Betas is a collaboration between some of YouTube’s most popular creators, <b>VanossGaming</b> (Evan Fong), <b>BasicallyIDoWrk</b> (Marcel Cunningham), <b>I AM WILDCAT</b> (Tyler Wine) and <b>Terroriser</b> (Brian Hanby) with more than 40 million subscribers across their channels, as well as production company 3BLACKDOT, award winning animation studio STARBURNS INDUSTRIES (the studio behind Rick & Morty) and showrunners Chris Bruno & David Lee (Facebook’s <i>Human Discoveries</i>). In addition to Alpha Team, fans can expect to hear the voice talents of Hollywood heavy hitters like Paget Brewster (<i>Friends</i>), Stephanie Beatriz (<i>Brooklyn Nine-Nine</i>), Chris Parnell (<i>SNL</i>), Brent Morin (<i>Chelsea Lately</i>) and John DiMaggio (<i>Futurama</i>).</p>
              <iframe
                  class="embed-responsive-item"
                  title="Alpha Betas - Official Trailer"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/_PUt6q8KRsE"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              <a
                href="https://alphabetas.3blackdot.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="ab-button mx-auto d-block btn btn-light">
                  ALPHA BETAS MERCH
                </button>
              </a>
              <br />
              <TwitterTweetEmbed tweetId={"1276238054395707395"} />
              <InstagramEmbed
                url="https://www.instagram.com/p/CBvyQ7dJL2d/"
                maxWidth={550}
                hideCaption={true}
                containerTagName="blockquote"
                protocol=""
                injectScript
                onLoading={() => {}}
                onSuccess={() => {}}
                onAfterRender={() => {}}
                onFailure={() => {}}
              />
              <InstagramEmbed
                url="https://www.instagram.com/p/CBvyrWmnqW8/"
                maxWidth={550}
                hideCaption={true}
                containerTagName="blockquote"
              />
              <InstagramEmbed
                url="https://www.instagram.com/p/CBvyxBmg3OY/"
                maxWidth={550}
                hideCaption={true}
                containerTagName="blockquote"
              />
              <InstagramEmbed
                url="https://www.instagram.com/p/CBvydIGgeT8/"
                maxWidth={550}
                hideCaption={true}
                containerTagName="blockquote"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 section-alphabetas-services">
              <img
                className="img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset-Services.png"
                alt=""
              />
              <Link to="/services/merchandise">
                <h4>MERCHANDISE</h4>
              </Link>
              <Link to="/services/content-production">
                <h4>CONTENT ORIGINAL</h4>
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AlphaBetasPage
