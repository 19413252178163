import React, { Component } from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import Header from "../components/header"
import { withAuthenticator } from "aws-amplify-react"

const MyTheme = {
  container: {
    backgroundColor: "#fff",
    height: "100vh",
    marginTop: "0",
    margin: "0 auto 50px",
    paddingTop: "5%",
  },
  signInButtonIcon: { display: "none" },
  googleSignInButton: { backgroundColor: "red", borderColor: "red" },
  hint: { display: "none" },
  sectionFooterSecondaryContent: { display: "none" },
  sectionFooterPrimaryContent: { margin: "auto" },
  sectionHeader: { display: "none" },
  inputLabel: { display: "none" },
  button: {
    backgroundColor: "#000",
    fontFamily: "proxima-nova, sans-serif",
    fontWeight: "600",
    fontSize: "14px",
  },
}

export const data = graphql`
  query {
    video: file(relativePath: { eq: "3BD_Sizzle_07-01-2020.mp4" }) {
      publicURL
    }
  }
`

class SizzlePage extends Component {
  state = {
    data: this.props.data,
  }
  render() {
    return (
      <div>
        <Header />
        <Seo title="3BLACKDOT SIZZLE" />
        <div className="container-fluid sizzlePage">
          <div className="row justify-content-center">
            <div className="col-10 col-md-10">
              <h3 className="text-center">3BLACKDOT Newfronts 2020</h3>
              <div className="embed-responsive embed-responsive-16by9">
                <video width="100%" controls>
                  <source
                    src={`${this.state.data.video.publicURL}`}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

// export default SizzlePage

export default withAuthenticator(SizzlePage, false, [], null, MyTheme)
