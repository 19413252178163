import React, { Component } from "react"
import Seo from "../../components/seo"
import { graphql } from "gatsby"

import Header from "../../components/header"

export const data = graphql`
  query {
    betaward: file(relativePath: { eq: "bet-awards.png" }) {
      childImageSharp {
        id
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

class PartyChatPage extends Component {
  state = {
    data: this.props.data,
  }
  render() {
    return (
      <div>
        <Header />
        <Seo title="Party Chat" />
        <div className="container-fluid partychat">
          <div className="row">
            <div className="col-12 partychatblock ">
              <h2>
                PARTY<br />CHAT
              </h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10 col-md-6 section-partychat-one">
              <p>Party Chat, starring gaming talent and the new voice of Cleveland on Family Guy, Arif AKA Azerrz, was released on Azerrz’s YouTube channel. The project is written and directed by Leon Chills, who wrote on Netflix’s SPINNING OUT and recently sold SHADOW FORCE to Lionsgate, which will star Kerry Washington, Sterling K. Brown and be directed by Rise of Skywalker’s Victoria Mahoney. The creative utilizes an innovative format, and touches on the idea that millennials use video games as a means to connect, now more than ever.</p>
              <p>Logline: Four 20-something best friends, living in different cities and navigating their separate life paths, are brought back together through their highly anticipated video game sessions. Think THE LEAGUE, but for bros who love gaming and it acts as the connective tissue that allows their individual storylines to intersect.</p>
              <div class="embed-responsive embed-responsive-16by9 videoPost">
              <iframe
                  class="embed-responsive-item"
                  title="We Are 3BLACKDOT"
                  src="https://www.youtube.com/embed/wMcQreJQsHg"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
}

export default PartyChatPage
