import React, { Component } from "react"
import { Link } from "gatsby"
import Seo from "../../components/seo"

import Header from "../../components/header"
class GameDevelopmentPage extends Component {
 
  render() {
    return (
      <div>
        <Header />
        <Seo title="Game Development" />
        <div className="container-fluid gamedevelopment">
          <div className="row">
            <div className="col-12 gamedevelopmentblock ">
              <h2>
                GAME<br></br>DEVELOPMENT
              </h2>
              <p>Let’s build communities together.</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10 col-md-3 section-gamedevelopment-one">
              <h3>Fun to Play, Fun to Watch</h3>
              <p>
                Our games are fun to play. That shouldn’t come as a surprise. But we’re here for audiences, too. So we
                build games that viewers love to watch. Because the future of gaming isn’t just gameplay, it’s
                spectatorship.
              </p>
              <h3>We Make Sandboxes</h3>
              <p>
                For our audiences and our influencers, games are more than entertainment. They’re expression. They are a
                manifestation of ideals, beliefs, and values.
              </p>
              <p>
                The best games are a community of contributors and stakeholders and these are the worlds we set out to
                create. If you prefer building blank canvases over static deliverables, then reach out and we’ll help
                breath life into your project.
              </p>
            </div>
            <div className="col-10 offset-md-1 col-md-3 section-gamedevelopment-two">
              <h3>Studio Capabilities</h3>
              <p>
                Our in house team of illustrators, modelers, and engineers can conceptualize, develop, and publish games
                on any platform. 
              </p>
              <p>
                Brands use our services to build interactive products designed for youth culture, the consumers who rely
                on gaming as their go-to for entertainment and socialization.
              </p>
              <h3>Let’s Start Coding</h3>
              <p>
                There’s always an appetite for the next breakthrough title but it won’t be yours if you never get
                started. Reach out. Download your ideas onto the team and the devs will hit the ground running, on their
                way to the next big hit.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 section-gamedevelopment-related">
              <h4>RELATED PROJECTS</h4>
            </div>
          </div>
          <div className="row section-gamedevelopment-related-projects">
            <div className="col-6 col-md-3 project deadrealm">
              <Link to="/projects/dead-realm">
                <h4>
                  DEAD<br></br>REALM
                </h4>
              </Link>
            </div>
            <div className="col-6 col-md-3 project misbits">
              <Link to="/projects/misbits">
                <h4>MISBITS</h4>
              </Link>
            </div>
            <div className="col-6 col-md-3 project clown">
              <Link to="/projects/clownpocalypse">
                <h4>CLOWNPOCALYPSE</h4>
              </Link>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
}

export default GameDevelopmentPage
