import React, { Component } from "react"
import { Link } from "gatsby"
import Seo from "../../components/seo"

import Header from "../../components/header";
import Footer from "../../components/footer";

class PublishingPage extends Component {
  
  render() {
    let isHeaderBgBlack = false;
    const canonicalUrl = this.props.location.pathname;
    if (canonicalUrl === "/services/publishing/") {
      isHeaderBgBlack = true;
    } 
    return (
      <div>
        <Header isHeaderBgBlack={isHeaderBgBlack} />
        <Seo title="Publishing" />
        <div className="bg-yellow-100 proximaNova p-10 lg:px-16 lg:py-20 text-black">
          <div className="my-10">
            <div className="col-12 publishingblock ">
            <h2 className="knockoutHTF67 px-1 lg:px-3 inline-block bg-black m-0 text-5xl lg:text-6xl uppercase text-yellow-100 lg:tracking-wide mb-5">Publishing</h2>
              <p>We'll make the world care about what you do.</p>
            </div>
          </div>
          <div className="my-10">
            <div className="col-10 col-md-3 section-publishing-one">
              <h3 className="mb-7 knockoutHTF47 tracking-wide text-4xl lg:text-5xl uppercase mt-0 mb-0 mt-3">Reach the World</h3>
              <p>
                Have great content? Awesome. Now make the world care. Even work
                from the most talented creators won’t see the light of day
                without a vehicle for reaching the masses.
              </p>
              <p>
                It’s our job to find your audience and get them to listen,
                watch, or play whatever it is you make. Tell us who you are and
                what you do so we can call the reporters, book the podcasts, and
                contact the influencers.
              </p>
              <h3>We Own Every Stage</h3>
              <p>
                We are your agency. And your PR firm. And your research team.
                And your media buyers. We own the audience, the distribution,
                and the ad creative. With everything under our roof, we’ll make
                sure the right audience gets the right message at the right
                time.
              </p>
            </div>
            <div className="col-10 offset-md-1 col-md-3 section-publishing-two">
              <h3 className="mb-7 knockoutHTF47 tracking-wide text-4xl lg:text-5xl uppercase mt-0 mb-0 mt-3">Don’t Go It Alone</h3>
              <p>
                Self-publishing is fraught with pitfalls and common
                mistakes—been there, done that. Take advantage of our
                hard-earned expertise to give your project its best chance at
                success.
              </p>
              <h3>Get Started</h3>
              <p>
                Inventory is limited and the schedule fills fast. Get in touch
                so we can start delivering your message to audiences who will
                obsess over your brand, buy your product, and remain loyal for
                life.
              </p>
            </div>
          </div>
          <div className="">
            <div className="col-12 section-publishing-related">
              <h4>RELATED PROJECTS</h4>
            </div>
          </div>
          <div className="">
            <div className="col-6 col-md-3 project vanoss">
              <Link to="/projects/vanoss">
                <h4>Vanoss</h4>
              </Link>
            </div>
            <div className="col-6 col-md-3 project littlepieces">
              <Link to="/projects/a-million-little-pieces">
                <h4>
                  A MILLION<br></br>LITTLE PIECES
                </h4>
              </Link>
            </div>
            <div className="col-6 col-md-3 project eatbrainlove">
              <Link to="/projects/eat-brains-love">
                <h4>
                  EAT<br></br>BRAINS<br></br>LOVE
                </h4>
              </Link>
            </div>
          </div>
        </div>
        
        <Footer /> 
      </div>
    )
  }
}

export default PublishingPage
