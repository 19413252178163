import React from "react"
import { graphql } from "gatsby"
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '../../node_modules/@splidejs/splide/dist/css/splide.min.css'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'
import { Link } from "gatsby"
import Typewriter from 'typewriter-effect';

import Header from "../components/header"
import Seo from "../components/seo"
import Footer from "../components/footer"

import sizzle from '../images/3bd-sizzle-reel.mp4'
import videoproject1 from '../images/video_abloop.mp4'
import videoproject2 from '../images/video_gwbloop.mp4'
import videoproject3 from '../images/video_nlploop.mp4'

import production2 from '../images/production-image-2.png'
import alphabetas from '../images/project-alphabetas-thumbnail.png'
import gamingwhileblack from '../images/project-gwb-thumbnail.png'
import neonlove from '../images/project-neonlove-thumbnail.png'

export default function IndexPage( {data} ) {

    const toggleMute = () => {
      const play = document.getElementById("play");
      const unmute = document.getElementById("unmute");
      const video = document.getElementById("heroVideo")
      if (video.muted) {
        video.muted = false;
        video.currentTime = 0;
        play.style.display = "none";
        unmute.style.display = "none";
      } else {
        video.muted = true;
        play.style.display = "block";
        unmute.style.display = "block";
      }
    }

    const toggleHoverPlay = () => {
      const elem = document.getElementById("heroVideo")
      const play = document.getElementById("play");
      elem.onmouseover = function() {
              play.style.display = "inline";
      };
      elem.onmouseout = function() {
              play.style.display = "none";
      };
    }

    const projectsData = data.webiny.listProjects.data

    return (
      <div className="bg-black">
        <Seo title="Home" />
        <Header />
        <ParallaxProvider>
        <div className="grid mx-auto pt-15 sm:pt-0 -z-0">
          <Parallax translateY={[-32, 20]}>
            <div className="">
                <button onClick={toggleMute} id="play" className="drop-shadow-lg" />
                <button onClick={toggleMute} id="unmute" className="drop-shadow-lg" />
            </div>
            <video width="100%" 
              autoPlay 
              loop 
              muted 
              playsInline 
              id="heroVideo" 
              onClick={toggleMute} 
              onMouseOver={toggleHoverPlay}
              className="heroVideo mt-10 lg:mt-0"
            >
              <source
                src={sizzle}
                type="video/mp4"
              />
            </video>
          </Parallax>
        </div>
        
        <div className="mx-auto">
          <Parallax translateY={[-30, 20]}>
            <div className="bg-yellow-100 grid grid-cols-1 p-10 lg:px-16 lg:py-20 text-5xl lg:text-6xl text-yellow-100 ">
              <h2 className="knockoutHTF67 m-0 text-left text-black text-6xl uppercase lg:tracking-wide">3BLACKDOT is</h2>
              <div className="w-full">
                <h2 className="knockoutHTF67 px-1 lg:px-3 inline-block bg-black m-0 text-6xl lg:text-xxl uppercase text-yellow-100 lg:tracking-wide">
                <Typewriter
                  options={{
                    autoStart: true,
                    loop: true,
                    delay: 80,
                  }}
                  onInit={(typewriter) => {
                    typewriter.typeString('Entertainment')
                      .pauseFor(2000)
                      .deleteAll()
                    typewriter.typeString('Culture')
                      .pauseFor(2000)
                      .deleteAll()
                    typewriter.typeString('Belonging')
                      .pauseFor(2000)
                      .deleteAll()
                    typewriter.typeString('Black-owned')
                      .pauseFor(3000)
                      .deleteAll()
                      .start();
                  }}
                />
                </h2>
              </div>
            </div>
          </Parallax>

          <Parallax translateY={[5, -10]}>
            <div className="px-0 lg:px-16 lg:py-20 bg-black text-yellow-100">
              <h2 className="knockoutHTF47 mb-0 text-center text-5xl lg:text-6xl uppercase tracking-wide" id="ourCommunity">OUR COMMUNITIES</h2>
              <div className="columns-1 lg:columns-3 text-yellow-100 py-5 lg:gap-16 lg:py-10">
              
                <Parallax translateY={[10, -5]}>
                <div className="w-full">
                    <a href="https://www.youtube.com/channel/UCsY9kIH2VGzI7MQJGRIx_hw" target="_blank" rel="noopener noreferrer" className="">
                    <video width="100%" poster={alphabetas} onMouseOver={event => event.target.play()} onMouseOut={event => event.target.load()} loop muted playsInline id="videoproject1">
                      <source
                        src={videoproject1}
                        type="video/mp4"
                      />
                    </video>
                    </a>
                    <Parallax translateY={[10, -20]}>
                      <div className="px-10 py-8 inline-block sm:py-10 lg:px-0">
                        <a href="https://www.youtube.com/channel/UCsY9kIH2VGzI7MQJGRIx_hw" target="_blank" rel="noopener noreferrer" className="">
                          <h2 className="knockoutHTF47 tracking-wide text-4xl lg:text-5xl uppercase mt-0 mb-0 mt-3">ALPHA BETAS</h2>
                          <p className="proximaNova text-sm leading-5 mb-0">Adult animation series with top gaming creators VanossGaming, WILDCAT, BasicallyIDoWrk, Terroriser, and Octopie Studio, and Bruno & Lee.</p>
                        </a>
                      </div>
                    </Parallax>
                  </div>
                </Parallax>
                
                <Parallax translateY={[10, -5]}>
                  <div className="w-full">
                    <a href="https://www.youtube.com/channel/UCj2-UosnU6yLZ3ImtV_FvdQ" target="_blank" rel="noopener noreferrer" className="">
                      <video width="100%" poster={gamingwhileblack} onMouseOver={event => event.target.play()} onMouseOut={event => event.target.load()} loop muted playsInline id="videoproject1">
                        <source
                          src={videoproject2}
                          type="video/mp4"
                        />
                      </video>
                    </a>
                    <Parallax translateY={[10, -20]}>
                      <div className="px-10 py-8 inline-block sm:py-10 lg:px-0">
                        <a href="https://www.youtube.com/channel/UCj2-UosnU6yLZ3ImtV_FvdQ" target="_blank" rel="noopener noreferrer" className="">
                          <h2 className="knockoutHTF47 tracking-wide text-4xl lg:text-5xl uppercase mt-0 mb-0 mt-3">Gaming While Black</h2>
                          <p className="proximaNova text-sm leading-5 mb-0">Conversational formats and livestreams featuring diverse artists and personalities sharing their creativity and experiences without compromise.</p>
                        </a>
                      </div>
                    </Parallax>
                  </div>
                </Parallax>

                <Parallax translateY={[10, -5]}>
                  <div className="w-full">
                  <video width="100%" poster={neonlove} onMouseOver={event => event.target.play()} onMouseOut={event => event.target.load()} loop muted playsInline id="videoproject1">
                      <source
                        src={videoproject3}
                        type="video/mp4"
                      />
                    </video>
                    <Parallax translateY={[10, -20]}>
                      <div className="px-10 py-8 inline-block sm:py-10 lg:px-0">
                        <h2 className="knockoutHTF47 tracking-wide text-4xl lg:text-5xl uppercase mt-0 mb-0 mt-3">Neon Love</h2>
                        <p className="proximaNova text-sm leading-5 mb-0">Upcoming digital series shining a light on the fluidity of identity and championing self–development.</p>
                      </div>
                    </Parallax>
                  </div>
                </Parallax>
              </div>
            </div>
          </Parallax>

          <Parallax translateY={[5, -5]}>
            <div className="p-10 lg:px-16 lg:py-10 bg-yellow-100">
              <Parallax translateY={[-10, 5]}>
              <h2 className="knockoutHTF67 mb-0 lg:mb-10 text-center text-5xl lg:text-6xl uppercase tracking-wide">FEATURED PROJECTS</h2>
              </Parallax>
              <div className="grid">
                <div className="overflow-auto py-10 lg:p-0">
                  <Splide 
                    options={ {
                      pagination: false,
                      width : '100%',
                      perPage: 4,
                      gap   : '1rem',
                      drag   : 'free',
                      snap: true,
                      type   : 'loop',
                      autoplay: true,
                      breakpoints: {
                        1024: {
                          perPage: 2,
                        },
                        768: {
                          perPage: 1,
                          snap: false,
                        },
                      },
                    } }
                    aria-label="latest-projects">
                    
                    {projectsData.map(project => {
                    return (project.visibility === true) ? 
                      <SplideSlide>
                        <div className="w-full aspect-video">
                          {project.link ? 
                            <Link to={project.link} target="_blank">
                              <img
                                className=""
                                src={project.image}
                                alt={project.name}
                              />
                            </Link>
                            :
                            <img
                              className=""
                              src={project.image}
                              alt={project.name}
                            />
                          }
                          <h3 className="text-sm mt-3 mb-0">{project.name}</h3>
                          <p className="text-xs">{project.achievement}</p>
                        </div>
                      </SplideSlide>  
                      :
                        null
                    })}

                  </Splide>
                </div>
              </div>
            </div>
          </Parallax>

          <Parallax translateY={[5, 0]}>
            <div className="bg-black text-yellow-100">
              <div className="container mx-auto px-10 pb-20 mt-10 lg:py-20">
                <h2 className="knockoutHTF47 mb-15 lg:mb-10 text-center text-5xl lg:text-6xl uppercase tracking-wide">Awards</h2>
                <div className="">
                  
                    <div className="border-b border-yellow-100 text-center lg:text-left mb-5 pb-5">
                      <h2 className="TitlingGothicFBWide mx-auto lg:container text-md lg:text-3xl m-0 uppercase">Fast Company</h2>
                      <span className="proximaNova text-xxs">(2022)</span>
                      <p className="proximaNova text-sm m-0">Top 10 most innovative companies in video</p>
                    </div>

                    <div className="border-b border-yellow-100 text-center lg:text-left mb-5 pb-5">
                      <h2 className="TitlingGothicFBWide mx-auto lg:container text-md lg:text-3xl m-0 uppercase">Streamys</h2>
                      <span className="proximaNova text-xxs">(2021)</span>
                      <p className="proximaNova text-sm m-0">Finalist for Social Impact Campaign</p>
                    </div>

                    <div className="border-b border-yellow-100 text-center lg:text-left mb-5 pb-5">
                      <h2 className="TitlingGothicFBWide mx-auto lg:container text-md lg:text-3xl m-0 uppercase">Digiday</h2>
                      <span className="proximaNova text-xxs">(2021)</span>
                      <p className="proximaNova text-sm m-0">Nominee for Greater Good Award for Racial Equality</p>
                    </div>

                    <div className="text-center lg:text-left">
                      <h2 className="TitlingGothicFBWide mx-auto lg:container text-md lg:text-3xl m-0 uppercase">CynopsisDigital Model D Award</h2>
                      <span className="proximaNova text-xxs">(2021)</span>
                      <p className="proximaNova text-sm m-0">Finalist for Best Branded Integration in a Web Series</p>
                    </div>

                </div>
              </div>
            </div>
          </Parallax>

        </div>

        <div className="bg-yellow-100 text-black">
        <Parallax translateY={[10, 0]}>
          <div className="container mx-auto p-10 py-20 lg:py-20">
            <div className="grid grid-cols-1 lg:grid-cols-12 grid-rows-1">
              
              <div className="col-span-5 mb-10 lg:mb-0">
                <Parallax translateY={[-30, 0]}>
                  <p className="proximaNova text-lg mb-0 lg:mb-10 leading-7 pr-5">3BLACKDOT is a global entertainment company that specializes in original and branded content, marketing, and production. We partner with audience driven creators, celebrities, and community focused brands, to create, produce, and finance original entertainment experiences that move Millennial and Gen Z audiences.</p>
                  <Link cover to="/about" className="" bg="#000000">
                    <button className="proximaNova text-yellow-100 border border-solid border-yellow-100 text-md mt-5 lg:mt-0 px-10 py-2 transition duration-500 ease-in-out bg-black hover:bg-yellow-100 hover:text-black hover:border hover:border-solid hover:border-black transform">ABOUT US</button>
                  </Link>
                </Parallax>
              </div>

              <div className="col-span-1" />

              <div className="col-span-6 -mt-10">
                <Parallax translateY={[20, -15]}>
                  <Link cover to="/about" className="" bg="#000000">
                    <img
                      className="w-full mx-auto"
                      src={production2}
                      alt=""
                    />
                  </Link>
                </Parallax>
              </div>
            </div>
          </div>
          </Parallax>
        </div>

        </ParallaxProvider>

        <Footer />

      </div>
    )
}

export const pageQuery = graphql
`
  query ListProjects {
    webiny {
      listProjects(sort: datePublished_ASC, limit: 100) {
        data {
          modelId
          link
          image
          id
          entryId
          datePublished
          createdOn
          body
          achievement
          slug
          visibility
          name
        }
      }
    }
  }
`
