import React from "react"
import { graphql } from "gatsby"
import DateFormatter from '../components/date-formatter'
import CarouselSlider from "../components/carousel-slider"
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'
import { Link } from "gatsby"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';

import Seo from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"


export default function CareersPage( {data} ) {

  const booksData = data.webiny.listBooks.data

  const dataBooks = data.webiny.listBooks.data;

  const booksByAuthor = {};
  dataBooks.forEach((book) => {
    const author = book.author;
    if (!booksByAuthor[author.id]) {
      booksByAuthor[author.id] = {
        id: author.id,
        authorName: author.authorName,
        books: [],
      };
    }
    if (book.visibility) {
      booksByAuthor[author.id].books.push(book);
    }
  });
  
  const sortedAuthors = Object.values(booksByAuthor).sort((a, b) =>
    a.authorName.localeCompare(b.authorName)
  );

  const booksAccordion = (
    <Accordion allowZeroExpanded>
      {Object.values(booksByAuthor).sort((a, b) => a.authorName.localeCompare(b.authorName)).map(author => (
        <AccordionItem key={author.id} uuid={author.id} className="border-b border-black">
          <AccordionItemHeading>
            <AccordionItemButton className="bg-[#d8dac6] p-3 md:p-5">
              <AccordionItemState>
                {({ expanded }) => (expanded ? 
                  <div className="grid grid-cols-12 text-sm md:text-base">
                    <div className="col-span-6">{author.authorName}</div>
                    <div className="col-span-5" />
                    <div className="col-span-1">
                      <div className="content-accordionClose w-4 float-right py-1" />
                    </div>
                  </div>
                  : 
                  <div className="grid grid-cols-12 text-sm md:text-base">
                    <div className="col-span-6">{author.authorName}</div>
                    <div className="col-span-5" />
                    <div className="col-span-1">
                      <div className="content-accordionOpen w-4 float-right py-1" />
                    </div>
                  </div>
                )}
              </AccordionItemState>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="bg-[#d8dac6]">
            {author.books.map(book => (
              <div key={book.title} className="grid grid-cols-1  md:grid-cols-12 gap-0 md:pt-5 pb-7">
                <div className="md:col-span-4 px-3 md:pl-5">
                  <img src={book.bookCover} alt="" className="w-full md:w-64" />
                </div>
                <div className="p-3 md:col-span-8 md:p-0 md:pr-5">
                  <div className="col-span-6">
                    <h3 className="md:font-normal knockoutHTF67 leading-10 text-5xl antialiased uppercase mb-2">{book.title}</h3>
                    <p className="mb-3 text-xs md:text-sm">Book by {book.author.authorName}</p>
                    <p className="mb-5 text-xs md:text-sm">{book.abstract}</p>
                    <p className="mb-0 text-xs md:text-sm">Published: <DateFormatter dateString={book.datePublished} /></p>
                    <p className="mb-0 text-xs md:text-sm">Edition: {book.bookEdition}</p>
                    <p className="mb-0 text-xs md:text-sm">Format: {book.bookFormat}</p>
                    {book.publisher && (
                      <p className="text-xs md:text-sm">Publisher: {book.publisher}</p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );

    return (
      <div>
        <Seo title="3BLACKDOT Publishing" />
        <Header />
        <ParallaxProvider>

        <div className="bg-black">


          <Parallax translateY={[-10, 5]}>
            <div className="bg-[#171717] pt-20 md:pt-16">
              <div className="container mx-auto text-pastel-100 px-10 py-20">
                <div className="grid grid-cols-1 lg:grid-cols-8">
                  <div className="col-span-5">
                    <h1 className="knockoutHTF67 text-5xl lg:text-8xl uppercase tracking-wide text-left mb-0 md:mb-0">Publishing</h1>
                    <p className="mb-0 md:mb-10">Telling Stories. Lifting Voices.</p>
                    <Link cover to="#ourBooks" className="" bg="#000000">
                      <button className="proximaNova text-yellow-100 border border-solid border-yellow-100 text-md mt-5 lg:mt-0 px-10 py-2 transition duration-500 ease-in-out bg-black hover:bg-yellow-100 hover:text-black hover:border hover:border-solid hover:border-black transform">BOOKS</button>
                    </Link>
                  </div>
                  <div className="col-span-1" />
                  <div className="text-md proximaNova col-span-2 mt-5 md:mt-0">
                    <p className="mb-5">3BLACKDOT develops book IP ranging from picture books to adult novels. Creators of multiple New York Times bestsellers, 3BD works with writers, content partners, and publishers to engage readers from childhood to adulthood.</p>
                  </div>
                </div>
              </div>
            </div>
          </Parallax>


          <Parallax translateY={[10, 0]} className="-mt-28 md:-mt-20">
          <div className="bg-black text-orange-100">
            <div className="container mx-auto">
              <div className="px-10 py-5 md:py-20">
                <h2 className="knockoutHTF67 text-5xl lg:text-6xl uppercase tracking-wide text-center my-10 md:mb-20">Featured Books</h2>
                <div className="proximaNova grid grid-cols-1 lg:grid-cols-1 gap-5">

                  <div className="PublishingPage">
                    <div className="py-0 md:py-10 lg:p-0">
                      <CarouselSlider carousel={booksData} slidesPerPage={3} type="booksData" />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          </Parallax>


          <div id="ourBooks" />
            <Parallax translateY={[5, -10]}>
            <div className="bg-pastel-100">
              <div className="container mx-auto">
                <div className="p-5 md:px-10 md:py-20">
                  <h2 className="knockoutHTF67 text-5xl lg:text-6xl uppercase mb-10 tracking-wide text-center">BOOKS by Author</h2>
                  {/* <div className="grid grid-cols-2 gap-5 bg-black text-pastel-100 uppercase px-5 py-3">
                    <div className="">Title</div>
                    <div className="">Author</div>
                  </div> */}
                  <div className="proximaNova grid grid-cols-1 max-w-6xl mx-auto gap-5">
                  
                  {booksAccordion}

                  {/* <Accordion allowZeroExpanded>
                        {booksData.map(book => {
                        return (book.visibility === true) ? 
                        <AccordionItem
                          key={book.title}
                          uuid={book.entryId}
                          className="border-b border-black"
                        >
                          <AccordionItemHeading>
                            <AccordionItemButton className="bg-[#d8dac6] p-3 md:p-5">

                            <AccordionItemState>
                                  {({ expanded }) => (expanded ? 
                                  <div className="grid grid-cols-12 text-sm md:text-base">
                                    <div className="col-span-6">{book.title}</div>
                                    <div className="col-span-5">{book.author.authorName}</div>
                                    <div className="col-span-1">
                                      <div className="content-accordionClose w-4 float-right py-1" />
                                    </div>
                                  </div>
                                  : 
                                  <div className="grid grid-cols-12 text-sm md:text-base">
                                    <div className="col-span-6">{book.title}</div>
                                    <div className="col-span-5">{book.author.authorName}</div>
                                    <div className="col-span-1">
                                      <div className="content-accordionOpen w-4 float-right py-1" />
                                    </div>
                                  </div>
                                  )}
                            </AccordionItemState>

                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel className="bg-[#d8dac6]">
                            <div className="grid grid-cols-1 md:grid-cols-12 gap-0 md:pt-5 md:pb-10">
                              <div className="md:col-span-6 p-3 md:pl-5">
                                <img src={book.bookCover} alt="" className="w-full md:w-64" />
                              </div>
                              <div className="p-3 md:col-span-5 md:p-0">
                                <div className="col-span-6">
                                  <h3 className="md:font-normal knockoutHTF67 leading-10 text-5xl antialiased uppercase mb-2">{book.title}</h3>
                                  <p className="mb-3 text-xs md:text-sm">Book by {book.author.authorName}</p>
                                  <p className="mb-5 text-xs md:text-sm">{book.abstract}</p>
                                  <p className="mb-0 text-xs md:text-sm">Published: <DateFormatter dateString={book.datePublished} /></p>
                                  <p className="mb-0 text-xs md:text-sm">Edition: {book.bookEdition}</p>
                                  <p className="mb-0 text-xs md:text-sm">Format: {book.bookFormat}</p>
                                  {book.publisher && (
                                    <p className="text-xs md:text-sm">Publisher: {book.publisher}</p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                        :
                        null
                        })}
                    </Accordion> */}

                    <div className="my-24 px-5 content-center inline-block mx-auto">
                      <Accordion allowZeroExpanded>
                        <AccordionItem>
                          <AccordionItemHeading>
                              <AccordionItemButton>
                                <button className="proximaNova text-yellow-100 border border-solid border-yellow-100 text-md mt-0 px-10 py-2 transition duration-500 ease-in-out bg-black hover:bg-yellow-100 hover:text-black hover:border hover:border-solid hover:border-black transform">Contact Publishing</button>
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div className="py-10 text-center">
                              <span><a href="mailto:publishing@3blackdot.com">publishing@3blackdot.com</a></span>
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </Accordion>
                    </div>
            
                  </div>
                </div>
              </div>
            </div>
            
            </Parallax>

          </div>


        </ParallaxProvider>
        <Footer />
      </div>
    )
}


export const pageQuery = graphql
`
  query BookPosts {
    webiny {
      listBooks(sort: featuredOrderNumber_ASC, limit: 100) {
        data {
          title
          slug
          id
          entryId
          bookCover
          bookEdition
          bookFormat
          createdOn
          audience
          abstract
          about
          visibility
          featured
          featuredOrderNumber
          modelId
          numberOfPages
          publisher
          illustrator
          isbn
          datePublished
          author {
            slug
            authorName
            id
          }
        }
      }
      listAuthors(sort: createdOn_DESC) {
        data {
          slug
          authorName
          id
        }
      }
    }
  }
`
