import React, { Component } from "react"
import { Link } from "gatsby"
import Seo from "../../components/seo"

import Header from "../../components/header"

class ContentProductionPage extends Component {
 
  render() {
    return (
      <div>
        <Header />
        <Seo title="Content Production" />
        <div className="container-fluid contentproduction">
          <div className="row">
            <div className="col-12 contentproductionblock ">
              <h2>
                CONTENT<br></br>PRODUCTION
              </h2>
              <p>Come create IP with us.</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10 col-md-3 section-contentproduction-one">
              <h3>Creativity Drives Us</h3>
              <p>
                Making things is what we do. Creative challenges are what get us
                out of bed every morning. Where others see the ordinary, we see
                a story waiting to be told, a game world ready to be built, and
                inspiration around every corner.
              </p>
              <p>
                Have an idea for something new? Let’s nurture it. We’ll shape it
                into something great, then make it into something you can
                display, distribute, publish and profit from.
              </p>
              <h3>We Create IP </h3>
              <p>
                Storytelling is the most powerful tool there is. But when
                stories transcend any one platform they becomes IP. Where other
                production companies sell stories, 3BD optimizes the user
                experience by creating complete 360 degree content worlds
                centered on the user, allowing writers, directors, and talent
                the opportunity to connect with their fans through immersive
                content experiences. So our stories can become feature films,
                television series, video games, merchandise, literature and more
                - all of which are connected to the same central narrative.
              </p>
            </div>
            <div className="col-10 offset-md-1 col-md-3 section-contentproduction-two">
              <h3>Start Here</h3>
              <p>
                We create and finance new IP, by developing high-concept,
                moderate-budget projects with audience-driven creators. Having
                in-house departments in film, TV, gaming, merchandise, events
                and publishing, we can reduce the time to market a project, and
                give creators opportunities to execute creatively and
                participate financially in ancillary areas they would never see
                at more traditional companies.
              </p>
              <h3>Start Now</h3>
              <p>
                We’re your full service creative and physical producers. We’ll
                help you go from idea, to inception, and all the way to final
                product. And all you have to do is make the call.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 section-contentproduction-related">
              <h4>RELATED PROJECTS</h4>
            </div>
          </div>
          <div className="row section-contentproduction-related-projects">
            <div className="col-6 col-md-3 project alphabetas">
              <Link to="/projects/alpha-betas">
                <h4>Alpha Betas</h4>
              </Link>
            </div>
            <div className="col-6 col-md-3 project playstation">
              <Link to="/projects/playstation">
                <h4>PLAYSTATION</h4>
              </Link>
            </div>
            <div className="col-6 col-md-3 project vanoss">
              <Link to="/projects/vanoss">
                <h4>Vanoss</h4>
              </Link>
            </div>
            <div className="col-6 col-md-3 project littlepieces">
              <Link to="/projects/a-million-little-pieces">
                <h4>
                  A MILLION
                  <br />
                  LITTLE PIECES
                </h4>
              </Link>
            </div>
            <div className="col-6 col-md-3 project eatbrainlove">
              <Link to="/projects/eat-brains-love">
                <h4>
                  EAT<br></br>BRAINS<br></br>LOVE
                </h4>
              </Link>
            </div>
            <div className="col-6 col-md-3 project riot">
              <Link to="/projects/riot">
                <h4>
                  RIOT<br></br>LEAGUE OF LEGENDS
                </h4>
              </Link>
            </div>
            <div className="col-6 col-md-3 project childsplay">
              <Link to="/projects/orion-childs-play">
                <h4>CHILD’S PLAY</h4>
              </Link>
            </div>
            <div className="col-6 col-md-3 project queenslim">
              <Link to="/projects/queen-and-slim">
                <h4>QUEEN & SLIM</h4>
              </Link>
            </div>
            <div className="col-6 col-md-3 project clown">
              <Link to="/projects/clownpocalypse">
                <h4>CLOWNPOCALYPSE</h4>
              </Link>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
}

export default ContentProductionPage
