import React from "react"
import logoBlack from '../images/3blackdot-logo-white.svg'
import { Link } from "gatsby"

export default function Footer() {
  return (
    <footer>
        <div className="footer bg-black">
            <div className="container proximaNova mx-auto p-10">
                <div className="grid grid-cols-1 lg:grid-cols-12 text-orange-100">
                    <div className="col-span-2">
                        <Link cover to="/" className="" bg="#000000">
                            <img
                                className="w-36 lg:w-40 logo-black"
                                src={logoBlack}
                                alt="3BLACKDOT Logo"
                            />
                        </Link>
                        <div className="text-sm">
                            <ul class="list-none m-0">
                                <li className="my-2">925 N La Brea Ave, West Hollywood, CA 90038</li>
                            </ul>
                            <div className="">
                            contact@3blackdot.com
                            </div>
                        </div>
                    </div>
                    <div className="col-span-9"></div>
                    <div className="col-span-1">
                    <div class="proximaNova py-5 lg:py-2 lg:text-right grid lg:float-right grid-cols-1">            
                        <h2 className="text-sm uppercase mb-2">SOCIAL</h2>
                        <ul class="text-sm list-none m-0">
                            <li className="my-2"><a href="https://www.instagram.com/3blackdot" target="_blank" rel="noopener noreferrer">Instagram</a></li>
                            <li className="my-2"><a href="https://twitter.com/3BLACKDOT" target="_blank" rel="noopener noreferrer">Twitter</a></li>
                            <li className="my-2"><a href="https://www.linkedin.com/company/3blackdot" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
                        </ul>
                    </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg-black">
        <div className="container mx-auto p-10">
            <div className="proximaNova grid grid-cols-12 gap-2 lg:gap-5 text-sm text-orange-100 uppercase">
                <div className="col-span-12 lg:col-span-3">
                    <p className="m-0">3BLACKDOT HOLDINGS, LLC ALL RIGHTS RESERVED ©</p>
                </div>
                <div className="col-span-12 lg:col-span-7"></div>
                <div className="col-span-12 lg:col-span-2">
                    <ul className="list-none m-0 lg:float-right">
                    </ul>
                </div>
            </div>
        </div>
        </div>
    </footer>
  )
}