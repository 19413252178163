import React, { Component } from "react"
import Footer from "../components/footer"
import Seo from "../components/seo"
import Header from "../components/header"
import { Link } from "gatsby"

class ProjectsPage extends Component {
  
  render() {
    return (
      <div className="bg-black" onScroll={this.handleScroll}>
        <Header />
        <Seo title="Projects" />
        <div className="">
          <div className="container proximaNova mx-auto text-pastel-100 pt-20 lg:px-16 lg:pt-28 text-center lg:pb-0">
         
          <div className="">
          <div className="container mx-auto">
            <h2 className="knockoutHTF47 mb-0 text-center text-5xl lg:text-6xl uppercase tracking-wide mb-5">PROJECTS</h2>
            
          </div>
        </div>
         
           <div className="projects grid grid-cols-1 gap-5 lg:grid-cols-3 lg:gap-5">
              
            <div className="p-5 inline-block text-xs border border-olive-100 rounded-lg drop-shadow-lg text-black bg-pastel-100 text-slate-800 scale-100 hover:scale-105 transition duration-300 ease-out hover:ease-in hover:bg-black hover:text-white">
                    <h2>ALPHA BETAS</h2>
                </div>
                <div className="p-5 inline-block text-xs border border-olive-100 rounded-lg drop-shadow-lg text-black bg-pastel-100 text-slate-800 scale-100 hover:scale-105 transition duration-300 ease-out hover:ease-in hover:bg-black hover:text-white">
                    <h2>QUEEN & SLIM</h2>
                </div>
                <div className="p-5 inline-block text-xs border border-olive-100 rounded-lg drop-shadow-lg text-black bg-pastel-100 text-slate-800 scale-100 hover:scale-105 transition duration-300 ease-out hover:ease-in hover:bg-black hover:text-white">
                    <h2>PARTY CHAT</h2>
                </div>
<div className="p-5  inline-block text-xs border border-olive-100 rounded-lg drop-shadow-lg text-black bg-pastel-100 text-slate-800 scale-100 hover:scale-105 transition duration-300 ease-out hover:ease-in hover:bg-black hover:text-white">
                    <h2>PLAYSTATION</h2>
                </div>
<div className="p-5  inline-block text-xs border border-olive-100 rounded-lg drop-shadow-lg text-black bg-pastel-100 text-slate-800 scale-100 hover:scale-105 transition duration-300 ease-out hover:ease-in hover:bg-black hover:text-white">
                    <h2>VANOSS</h2>
                </div>
<div className="p-5  inline-block text-xs border border-olive-100 rounded-lg drop-shadow-lg text-black bg-pastel-100 text-slate-800 scale-100 hover:scale-105 transition duration-300 ease-out hover:ease-in hover:bg-black hover:text-white">
                    <h2>LAUREN Z SIDE</h2>
                </div>
<div className="p-5  inline-block text-xs border border-olive-100 rounded-lg drop-shadow-lg text-black bg-pastel-100 text-slate-800 scale-100 hover:scale-105 transition duration-300 ease-out hover:ease-in hover:bg-black hover:text-white">
                    <h2>MISBITS</h2>
                </div>
<div className="p-5  inline-block text-xs border border-olive-100 rounded-lg drop-shadow-lg text-black bg-pastel-100 text-slate-800 scale-100 hover:scale-105 transition duration-300 ease-out hover:ease-in hover:bg-black hover:text-white">
                    <h2>SOFT SERVE</h2>
                </div>
<div className="p-5  inline-block text-xs border border-olive-100 rounded-lg drop-shadow-lg text-black bg-pastel-100 text-slate-800 scale-100 hover:scale-105 transition duration-300 ease-out hover:ease-in hover:bg-black hover:text-white">
                    <h2>CLOWNPOCALYPSE</h2>
                </div>
<div className="p-5  inline-block text-xs border border-olive-100 rounded-lg drop-shadow-lg text-black bg-pastel-100 text-slate-800 scale-100 hover:scale-105 transition duration-300 ease-out hover:ease-in hover:bg-black hover:text-white">
                    <h2>3AM</h2>
                </div>
<div className="p-5  inline-block text-xs border border-olive-100 rounded-lg drop-shadow-lg text-black bg-pastel-100 text-slate-800 scale-100 hover:scale-105 transition duration-300 ease-out hover:ease-in hover:bg-black hover:text-white">
                    <h2>DEAD REALM</h2>
                </div>
<div className="p-5  inline-block text-xs border border-olive-100 rounded-lg drop-shadow-lg text-black bg-pastel-100 text-slate-800 scale-100 hover:scale-105 transition duration-300 ease-out hover:ease-in hover:bg-black hover:text-white">
                    <h2>
                      RIOT
                      LEAGUE OF LEGENDS NEW PLAYERS
                      CAMPAIGN
                    </h2>
                </div>
<div className="p-5  inline-block text-xs border border-olive-100 rounded-lg drop-shadow-lg text-black bg-pastel-100 text-slate-800 scale-100 hover:scale-105 transition duration-300 ease-out hover:ease-in hover:bg-black hover:text-white">
                    <h2>Orion Child's Play</h2>
                </div>
<div className="p-5  inline-block text-xs border border-olive-100 rounded-lg drop-shadow-lg text-black bg-pastel-100 text-slate-800 scale-100 hover:scale-105 transition duration-300 ease-out hover:ease-in hover:bg-black hover:text-white">
                    <h2>
                      A MILLION LITTLE PIECES
                    </h2>
                </div>
<div className="p-5  inline-block text-xs border border-olive-100 rounded-lg drop-shadow-lg text-black bg-pastel-100 text-slate-800 scale-100 hover:scale-105 transition duration-300 ease-out hover:ease-in hover:bg-black hover:text-white">
                    <h2>
                      EAT BRAINS LOVE
                    </h2>
                </div>
            </div>
                <Link cover to="/about" className="grid grid-cols-1 w-44 mx-auto m-10" bg="#000000">
                  <button className="text-yellow-100 border border-solid border-yellow-100 text-xs mt-5 lg:mt-0 px-10 py-2 transition duration-500 ease-in-out bg-black hover:bg-yellow-100 hover:text-black hover:border hover:border-solid hover:border-black transform">ABOUT US</button>
                </Link>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default ProjectsPage
