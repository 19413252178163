import React, { Component } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Seo from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"

export const data = graphql`
  query {
    curtis: file(
      relativePath: { eq: "curtis-jackson-50-cent-eli-roth-3blackdot.jpg" }
    ) {
      publicURL
    }
    BavaMedia: file(
      relativePath: { eq: "bucks_gaming.png" }
    ) {
      publicURL
    }
    newsfront: file(relativePath: { eq: "newfronts_gif_network.gif" }) {
      publicURL
    }
    alphabetas: file(relativePath: { eq: "Alpha-Betas.jpg" }) {
      childImageSharp {
        id
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    newsfrontlogo: file(relativePath: { eq: "newfronts-logo.jpg" }) {
      childImageSharp {
        id
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    clownpocalypse: file(relativePath: { eq: "CLOWNPOCALYPSE.jpg" }) {
      childImageSharp {
        id
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

class BlogPage extends Component {
  state = {
    data: this.props.data,
  }
  render() {
    return (
      <div>
        <Header />
        <Seo title="3BLACKDOT NEWS" />
        <div className="bg-black">
          <div className=" w-1/2 mx-auto text-pastel-100 text-center px-10 py-20">
            <div className="container-fluid newspage">
              <div className="row justify-content-center">
                <div className="col-10">
                  <img
                    className="img-fluid  mx-auto d-block news-title"
                    src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/news_tape.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-10 col-md-6 single-post">
                  <h2 className="post-title">NEW PARTNERSHIP ALERT</h2>
                  <p className="post-date">April 1, 2021</p>
                  <p className="post-subheadline">
                    Digital Studio 3BlackDot Inks Deal With Management Firm BavaMedia (Exclusive)
                  </p>
                  <div className="post-body">
                      <p>
                      We’ve signed an exclusive, one-year partnership with gaming talent management company BavaMedia. Through the partnership, 3BLACKDOT will provide Bava’s talent with a suite of services intended to maximize the talent’s creative and revenue potential in the marketplace under a new “sales-as-a-service” model. Bava has a pool of exclusive talent - including GhostNinja, PackAPuncher, Bucks, Ahrora, Nerpah, and FreshPanda which we’re excited to support with original programming deals, brand partnerships, and other ecommerce opportunities.
                      </p>
                      <p>
                        <a
                          href="https://www.hollywoodreporter.com/news/digital-studio-3blackdot-inks-deal-with-management-firm-bavamedia-exclusive"
                          target="_blank"
                          rel="noopener noreferrer"
                        >Read more in The Hollywood Reporter</a>
                      </p>
                    <a
                      href="https://www.hollywoodreporter.com/news/digital-studio-3blackdot-inks-deal-with-management-firm-bavamedia-exclusive"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        class="img-fluid d-block mx-auto"
                        src={`${this.state.data.BavaMedia.publicURL}`}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-10 col-md-6 single-post">
                  <h2 className="post-title">3BD UPCOMING PROJECT</h2>
                  <p className="post-date">October 14, 2020</p>
                  <p className="post-subheadline">
                    3BD Upcoming Three-Pic Deal with 50 Cent / Eli Roth
                  </p>
                  <div className="post-body">
                    <p>
                      <a
                      href="https://deadline.com/2020/10/curtis-jackson-50-cent-eli-roth-3blackdot-deal-three-movies-1234597503/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >Deadline - Curtis “50 Cent” Jackson Partners With Eli Roth, 3BlackDot For Three-Pic Deal</a>
                    </p>
                    <a
                      href="https://deadline.com/2020/10/curtis-jackson-50-cent-eli-roth-3blackdot-deal-three-movies-1234597503/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        class="img-fluid d-block mx-auto"
                        src={`${this.state.data.curtis.publicURL}`}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-10 col-md-6 single-post">
                  <h2 className="post-title">NEWFRONTS 2020 DEBUT</h2>
                  <p className="post-date">June 25, 2020</p>
                  <p className="post-subheadline">
                    3BLACKDOT gives its first NewFronts presentation, announcing two
                    new original series: Alpha Betas and Party Chat
                  </p>
                  <div className="post-body">
                    <p>
                      <a
                      href="https://www.adweek.com/digital/3blackdot-announces-2-new-shows-at-newfronts-debut/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >Adweek - 3BlackDot Announces 2 New Shows at NewFronts Debut</a>
                    </p>
                    <a
                      href="https://www.adweek.com/digital/3blackdot-announces-2-new-shows-at-newfronts-debut/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        class="img-fluid d-block mx-auto"
                        src={`${this.state.data.newsfront.publicURL}`}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-10 col-md-6 single-post">
                  <h2 className="post-title">ALPHA BETAS</h2>
                  <p className="post-date">June 25, 2020</p>
                  <p className="post-subheadline">
                    VanossGaming, I AM WILDCAT, BasicallyIDoWrk and Terroriser team
                    up with 3BLACKDOT, Starburns Industries, Chris Bruno and David
                    Lee to create the animated series ALPHA BETAS
                  </p>
                  <div className="post-body">
                    <p>
                      <a
                    href="https://www.vanityfair.com/culture/2020/06/alpha-betas-youtube-stars"
                    target="_blank"
                    rel="noopener noreferrer"
                  >Vanity Fair - Game On: Animated Series Alpha Betas Will Tap the Power of YouTube Stars</a>
                    </p>
                    <a
                      href="https://www.vanityfair.com/culture/2020/06/alpha-betas-youtube-stars"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Img
                        key={this.state.data.alphabetas.childImageSharp.id}
                        fluid={this.state.data.alphabetas.childImageSharp.fluid}
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-10 col-md-6 single-post">
                  <h2 className="post-title">NEWFRONTS 2020</h2>
                  <p className="post-date">May 17, 2020</p>
                  <p className="post-subheadline">
                    3BLACKDOT in IAB's Virtual NewFronts Lineup{" "}
                  </p>
                  <div className="post-body">
                    <p>
                      <a
                      href="https://variety.com/2020/digital/news/newfronts-2020-schedule-facebook-snap-tiktok-1234606552/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >Variety - NewFronts 2020 Schedule: Facebook, Snap, TikTok Join Streaming Lineup</a>
                    </p>
                    <a
                      href="https://variety.com/2020/digital/news/newfronts-2020-schedule-facebook-snap-tiktok-1234606552/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Img
                        key={this.state.data.newsfrontlogo.childImageSharp.id}
                        fluid={this.state.data.newsfrontlogo.childImageSharp.fluid}
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-10 col-md-6 single-post">
                  <h2 className="post-title">CLOWNPOCALYPSE</h2>
                  <p className="post-date">January 27, 2020</p>
                  <p className="post-subheadline">
                    3BLACKDOT and Eli Roth Developing 'Clownpocalypse'
                  </p>
                  <div className="post-body">
                    <p>
                      <a
                      href="https://deadline.com/2020/01/eli-roth-3blackdot-clownpocalypsehorror-franchise-1202843108/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >Deadline - Eli Roth & 3BlackDot Developing Horror Franchise ‘Clownpocalypse’</a>
                    </p>
                    <a
                      href="https://deadline.com/2020/01/eli-roth-3blackdot-clownpocalypsehorror-franchise-1202843108/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Img
                        key={this.state.data.clownpocalypse.childImageSharp.id}
                        fluid={this.state.data.clownpocalypse.childImageSharp.fluid}
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-10 col-md-6 single-post">
                  <h2 className="post-title">MISBITS</h2>
                  <p className="post-date">NOVEMBER 14, 2019</p>
                  <p className="post-subheadline">3BLACKDOT Unveils Misbits</p>
                  <div className="post-body">
                    <p>
                      <a
                      href="https://venturebeat.com/2019/11/14/3blackdot-unveils-misbits-combat-game-set-in-a-sandbox-cartoon-world/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >VentureBeat - 3BlackDot Unveils MisBits Combat Game Set in a Sandbox Cartoon World</a>
                    </p>
                    <div class="inline-block mx-auto">
                      <iframe
                        class="aspect-video w-full"
                        title="queen and slim trailer"
                        src="https://www.youtube.com/embed/oJia_sGnWsI?rel=0"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-10 col-md-6 single-post">
                  <h2 className="post-title">QUEEN & SLIM</h2>
                  <p className="post-date">JULY 19, 2018</p>
                  <p className="post-subheadline">
                    3BLACKDOT Cocreates, Produces, and Finances Romantic Drama
                    'Queen & Slim'
                  </p>
                  <div className="post-body">
                    <p>
                      <a
                      href="https://variety.com/2018/film/news/daniel-kaluuya-romance-queen-and-slim-1202878861/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >Variety News - Daniel Kaluuya to Star in Romantic Drama ‘Queen & Slim’ From Lena Waithe</a>
                    </p>
                    <div class="inline-block mx-auto">
                      <iframe
                        class="aspect-video w-full"
                        title="queen and slim trailer"
                        src="https://www.youtube.com/embed/JW4RgX08xXw?rel=0"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default BlogPage
