import React, { Component } from "react"
import { Link } from "gatsby"
import Seo from "../../components/seo"

import Header from "../../components/header"
import Footer from "../../components/footer"

class LiveEventsPage extends Component {
 
  render() {
    let isHeaderBgBlack = false;
    const canonicalUrl = this.props.location.pathname;
    if (canonicalUrl === "/services/live-events/") {
      isHeaderBgBlack = true;
    } 
    return (
      <div>
        <Header isHeaderBgBlack={isHeaderBgBlack} />
        <Seo title="Live Events" />
        <div className="bg-yellow-100 proximaNova p-10 lg:px-16 lg:py-20 text-black">
        <div className="my-10">
            <div className="">
            <h2 className="knockoutHTF67 px-1 lg:px-3 inline-block bg-black m-0 text-5xl lg:text-6xl uppercase text-yellow-100 lg:tracking-wide mb-5">
                LIVE EVENTS
              </h2>
              <p>Experiences go beyond clicks or likes.</p>
            </div>
          </div>
          <div className="">
            <div className="col-10 col-md-3 section-liveevents-one">
            <h3 className="mb-7 knockoutHTF47 tracking-wide text-4xl lg:text-5xl uppercase mt-0 mb-0 mt-3">Why Live Events?</h3>
              <p>
                In-person experiences take engagement to the next level. The right one is one they’ll remember forever
                and that’s a recipe for lifelong customers.
              </p>
              <h3>Our Portfolio</h3>
              <p>
                Tens of thousands have experienced the power of a 3BD event with dozens of successful happy hours,
                parties, brand activations, conferences, fan conventions, meet and greets, and concerts in our
                portfolio.
              </p>
              <p>
                Live art creation. Exciting musical performances. World-class catering. These are just some of what make
                3BLACKDOT parties uniquely memorable.
              </p>
            </div>
            <div className="">
            <h3 className="mb-7 knockoutHTF47 tracking-wide text-4xl lg:text-5xl uppercase mt-0 mb-0 mt-3">Events Done Right</h3>
              <p>
                Should your attendees feel exhilarated? Or is relaxed and satisfied a better fit for your brand?
                Crafting the right mood and atmosphere at your live event is as important and nuanced as branding your
                product or content.
              </p>
              <p>
                3BLACKDOT has the vendor network, experience, and creative talent to curate the perfect experience for
                your brand, product, or media launch.
              </p>
              <h3 className="mb-7 knockoutHTF47 tracking-wide text-4xl lg:text-5xl uppercase mt-0 mb-0 mt-3">Get Started</h3>
              <p>
                Don’t wait till the last minute to reach out because the sooner we start, the better your live
                experience will be. Get in touch with our Events Team now and we’ll start brainstorming the experiential
                concepts your fans will remember for life.
              </p>
            </div>
          </div>
          <div className="">
            <div className="">
              <h4>RELATED PROJECTS</h4>
            </div>
          </div>
          <div className="">
            <div className="">
              <Link to="/projects/3am">
                <h4 className="">3AM</h4>
              </Link>
            </div>
            <div className="">
              <Link to="/projects/orion-childs-play">
                <h4 className="">CHILD'S PLAY</h4>
              </Link>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default LiveEventsPage
