import React from "react"
import { graphql } from "gatsby"
import { ParallaxHover } from 'react-parallax-hover'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '../../node_modules/@splidejs/splide/dist/css/splide.min.css'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'
import Typewriter from 'typewriter-effect'
import Tabs, { TabPane } from 'rc-tabs'


import Seo from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"

import team1 from '../images/team-1.png'
import team2 from '../images/team-2.png'
import team3 from '../images/team-3.png'
import team4 from '../images/team-4.png'

export default function CareersPage( {data} ) {

    const jobsData = data.webiny.listJobs.data

    return (
      <div>
        <Seo title="3BLACKDOT Careers" />
        <Header />
        <ParallaxProvider>

        <div className="bg-pastel-100">

          <div className="pt-20 px-10 lg:px-16 lg:pt-28 lg:pb-0 mx-auto bg-black">
            
            <div className="grid grid-cols-1 inline-table max-h-[36rem] gap-5 lg:grid-cols-12 lg:h-screen lg:min-h-fit text-orange-100">
              <div className="col-span-5 mb-10 lg:mb-0">
                <h2 className="knockoutHTF67 w-full text-5xl xl:text-8xl uppercase lg:tracking-wide text-pastel-100 m-0"> TELL STORIES THAT</h2>
                <div className="px-2 inline-block lg:px-3 bg-pastel-100">
                    <h2 className="knockoutHTF67 mb-0 text-5xl xl:text-8xl uppercase lg:tracking-wide text-black">
                      <Typewriter
                        options={{
                          autoStart: true,
                          loop: true,
                          delay: 80,
                        }}
                        onInit={(typewriter) => {
                          typewriter.typeString('BATTLE INVISIBILITY')
                            .pauseFor(2000)
                            .deleteAll()
                          typewriter.typeString('STIR HEARTS')
                            .pauseFor(2000)
                            .deleteAll()
                          typewriter.typeString('IMPACT CULTURE')
                            .pauseFor(2000)
                            .deleteAll()
                            .start();
                        }}
                      />
                    </h2>
                  </div>
              </div>
              <div className="col-span-1 hidden lg:block lg:col-span-7 mb-10 lg:mb-0">
                <div className="grid inline-block lg:grid-cols-2 -ml-22 gap-5 lg:ml-0">
                  <div className="m-0 m-auto lg:absolute xl:right-96 lg:top-32 scale-100 hover:scale-110 transition duration-300 ease-out hover:ease-in hover:z-50">
                  <Parallax translateY={[40, -30]}>
                    <ParallaxHover scale width={300} height={343} rotation={9} shadow={6}>
                        <img
                          className=""
                          src={team1}
                          alt=""
                        />
                    </ParallaxHover>
                  </Parallax>
                  </div>
                  <div className="m-0 m-auto lg:absolute xl:right-80 lg:top-60 lg:top-72 scale-100 hover:scale-110 transition duration-300 ease-out hover:ease-in hover:z-50">
                  <Parallax translateY={[40, -30]}>
                    <ParallaxHover scale width={300} height={343} rotation={9} shadow={2}>
                      <img
                        className=""
                        src={team2}
                        alt=""
                      />
                    </ParallaxHover>
                  </Parallax>
                  </div>
                  <div className="m-0 m-auto lg:absolute lg:right-16 lg:top-64 scale-100 hover:scale-110 transition duration-300 ease-out hover:ease-in hover:z-50">
                  <Parallax translateY={[40, -30]}>
                    <ParallaxHover scale width={300} height={343} rotation={8} shadow={8}>
                      <img
                        className=""
                        src={team3}
                        alt=""
                      />
                    </ParallaxHover>
                  </Parallax>
                  </div>
                  <div className="m-0 m-auto lg:absolute lg:right-28 lg:top-32 scale-100 hover:scale-110 transition duration-300 ease-out hover:ease-in hover:z-50">
                  <Parallax translateY={[70, -30]}>
                    <ParallaxHover scale width={300} height={343} rotation={8} shadow={4}>
                      <img
                        className="w-full"
                        src={team4}
                        alt=""
                      />
                    </ParallaxHover>
                  </Parallax>
                  </div>
                </div>
              </div>
            </div>            

          </div>

          <div className="grid block bg-black pb-16 lg:hidden">
            <div className="">
              <Splide 
                options={ {
                  rewind: true,
                  type   : 'loop',
                  padding: { left: 20, right: 20 },
                  gap: 10,
                  perPage: 1,
                  pagination: false,
                  autoplay: true,
                  arrows: false,
                } }
                aria-label="team">
                <SplideSlide>
                  <div className="grid grid-cols-1 lg:grid-cols-2">
                      <img
                        className="w-full"
                        src={team1}
                        alt=""
                      />
                  </div>
                </SplideSlide>
                <SplideSlide>
                  <div className="grid grid-cols-1 lg:grid-cols-2">
                      <img
                        className="w-full"
                        src={team2}
                        alt=""
                      />
                  </div>
                </SplideSlide>
                <SplideSlide>
                  <div className="grid grid-cols-1 lg:grid-cols-2">
                      <img
                        className="w-full"
                        src={team3}
                        alt=""
                      />
                  </div>
                </SplideSlide>
                <SplideSlide>
                  <div className="grid grid-cols-1 lg:grid-cols-2">
                      <img
                        className="w-full"
                        src={team4}
                        alt=""
                      />
                  </div>
                </SplideSlide>
              </Splide>
            </div>
          </div>

          <Parallax translateY={[-10, 5]}>
            <div className="bg-black">
              <div className="container mx-auto text-pastel-100 px-10 py-20">
                <div className="grid grid-cols-1 lg:grid-cols-2">
                  <div className="">
                    <h2 className="knockoutHTF67 text-5xl lg:text-6xl uppercase tracking-wide text-left">BELONG HERE</h2>
                  </div>
                  <div className="text-md proximaNova">
                    <p className="mb-5">We are passionate about the intersection of gaming  x culture and how shared experiences unlock our human potential. We know that loving what you do and acting with purpose is the best kind of work.</p>
                    <p className="mb-5">Whether you are ideating breakthrough social content, scouting undeniable talent, or producing the next great digital series, we're united about drawing on a full range of experiences.</p>
                    <p className="mb-5">We hold diversity with inclusion as indelible to the fabric of 3BD. We see marginalized groups as multifaceted, not monolithic. We hold fandom as our north star because it all starts and ends with community. And, we give talent ownership, convene stakeholders, and translate access to creative and financial support into real-world impact.</p>
                  </div>
                </div>
              </div>
            </div>
          </Parallax>

          <Parallax translateY={[10, -5]}>
          <div className="bg-pastel-100">
            <div className="container mx-auto px-10 pt-20 pb-0 lg:py-20">
              <div className="grid">
                <div className="">
                  <h2 className="knockoutHTF67 text-5xl uppercase lg:text-6xl tracking-wide text-center">WHAT OUR TEAM SAYS ABOUT WORKING HERE</h2>
                </div>
              </div>
              <div className="proximaNova grid">
                <Tabs defaultActiveKey="1"> 
                    <TabPane tab="LIKE MOST" key="1" className="columns-1 md:columns-3 lg:columns-3 gap-0">
                      <div className="outline w-full outline-1 p-5 inline-block text-xs text-black bg-pastel-100 text-slate-800 transition duration-300 ease-out hover:ease-in hover:bg-black hover:text-white hover:outline-black">
                        "I like the freedom at 3BLACKDOT! I have the creative freedom to try new ideas, or new processes." 
                        <br /><br />
                        <strong>Joshua Peschka</strong><br />Production Team
                      </div>
                      <div className="outline w-full outline-1 p-5 inline-block text-sm text-black bg-pastel-100 text-slate-800 transition duration-300 ease-out hover:ease-in hover:bg-black hover:text-white hover:outline-black">
                        "I look forward to the collaborative environment. It's really rewarding to be able to come into an office where I know I have multiple people I can go to and help develop ideas." 
                        <br /><br />
                        <strong>Lee Chin</strong><br />Content Team
                      </div>
                      <div className="outline w-full outline-1 p-5 inline-block text-sm text-black bg-pastel-100 text-slate-800 transition duration-300 ease-out hover:ease-in hover:bg-black hover:text-white hover:outline-black">
                        "3BD has been extremely supportive of my remote working status and the entire organization has gone above and beyond to accommodate me. My manager has empowered me to work autonomously while simultaneously making me feel like I'm a core part of our marketing team. Overall, 3BD has been one of the best places I've worked thus far in my career." 
                        <br /><br />
                        <strong>Eric Anderson</strong><br />Marketing Team
                      </div>
                      <div className="outline w-full outline-1 p-5 inline-block text-sm text-black bg-pastel-100 text-slate-800 transition duration-300 ease-out hover:ease-in hover:bg-black hover:text-white hover:outline-black">
                        "It's always the people. Everyone who works at 3BD exudes creativity and passion into everything they do, which makes it such an inspiring place to work. They make challenges feel attainable and that you're never alone." 
                        <br /><br />
                        <strong>Ethan Wong</strong><br />Client Success Team
                      </div>
                      <div className="outline w-full outline-1 p-5 inline-block text-sm text-black bg-pastel-100 text-slate-800 transition duration-300 ease-out hover:ease-in hover:bg-black hover:text-white hover:outline-black">
                        "New challenges and experiences is the norm for working at 3BD. This organization is relentless in listening to the audience and the shifts of the market while addressing them head on. It's exciting and a privilege to work for a company that is carving out a new frontier in entertainment and media." 
                        <br /><br />
                        <strong>Chris Pyon</strong><br />Finance Team
                      </div>
                    </TabPane>
                    <TabPane tab="WORKPLACE CULTURE" key="2" className="columns-1 md:columns-3 lg:columns-3 gap-0">
                      <div className="outline w-full outline-1 p-5 inline-block text-sm text-black bg-pastel-100 text-slate-800 transition duration-300 ease-out hover:ease-in hover:bg-black hover:text-white hover:outline-black">
                        "I have nothing but positive things to say about the 3BD workplace. Being able to work remote but still having an office  is extremely useful. Having higher ups that are flexible and help get things done in a timely manner really emphasizes how things are done around here." 
                        <br /><br />
                        <strong>Naveen Sheik</strong><br />Talent Team
                      </div>
                      <div className="outline w-full outline-1 p-5 inline-block text-sm text-black bg-pastel-100 text-slate-800 transition duration-300 ease-out hover:ease-in hover:bg-black hover:text-white hover:outline-black">
                        "I love 3BD's trailblazing mentality. It's so refreshing to work at a company that doesn't rely on precedent and is constantly questioning the status quo." 
                        <br /><br />
                        <strong>Jane Pierce</strong><br />Production Team
                      </div>
                      <div className="outline w-full outline-1 p-5 inline-block text-sm text-black bg-pastel-100 text-slate-800 transition duration-300 ease-out hover:ease-in hover:bg-black hover:text-white hover:outline-black">
                        "I love the community that 3BD provides. Everyone works together effortlessly because there's a passion to drive forward the brand and align on our goals." 
                        <br /><br />
                        <strong>Bria Deans</strong><br />Marketing Team
                      </div>
                      <div className="outline w-full outline-1 p-5 inline-block text-sm text-black bg-pastel-100 text-slate-800 transition duration-300 ease-out hover:ease-in hover:bg-black hover:text-white hover:outline-black">
                        "3BD is a gem of a company where you are encouraged to discover and cultivate inherent skill sets to the tune of the company's goals and also, as importantly, your own personal growth." 
                        <br /><br />
                        <strong>William Mo</strong><br />Sales Team
                      </div>
                      <div className="outline w-full outline-1 p-5 inline-block text-sm text-black bg-pastel-100 text-slate-800 transition duration-300 ease-out hover:ease-in hover:bg-black hover:text-white hover:outline-black">
                        "3BD's culture is a mix of creativity and forward thinking individuals which makes the environment conducive to success. Everyone is on the same page and we work together to achieve the greater goal of making an impact in today's entertainment industry." 
                        <br /><br />
                        <strong>Ben Mercer</strong><br />People Team
                      </div>
                    </TabPane>
                    <TabPane tab="STANDOUT PROJECTS" key="3" className="columns-1 md:columns-3 lg:columns-3 gap-0">
                      <div className="outline w-full outline-1 p-5 inline-block text-sm text-black bg-pastel-100 text-slate-800 transition duration-300 ease-out hover:ease-in hover:bg-black hover:text-white hover:outline-black">
                        "Working on the Emo Nite movie has been a stand out project for me because I've been a huge fan of emo and pop punk music since I was in middle school. 3BD's content thesis is driven by superfans and tapping into passionate niche communities. Being able to work on a project that I'm personally a superfan of just reinforces the importance of that model for me." 
                        <br /><br />
                        <strong>Caroline Ohlson</strong><br />Content Team
                      </div>
                      <div className="outline w-full outline-1 p-5 inline-block text-sm text-black bg-pastel-100 text-slate-800 transition duration-300 ease-out hover:ease-in hover:bg-black hover:text-white hover:outline-black">
                        "Creating content that has accumulated over 7 million views" 
                        <br /><br />
                        <strong>Tabrin Gulley</strong><br />Marketing Team
                      </div>
                      <div className="outline w-full outline-1 p-5 inline-block text-sm text-black bg-pastel-100 text-slate-800 transition duration-300 ease-out hover:ease-in hover:bg-black hover:text-white hover:outline-black">
                        "Sitting in the crowd at Vidcon listening to the creators and producers involved in the Gaming While Black show discuss the meaningful impact we're aiming to achieve within BIPOC communities and feeling inspired to continue to work and meet those goals as a company." 
                        <br /><br />
                        <strong>Brendan Doherty</strong><br />Talent Team
                      </div>
                      <div className="outline w-full outline-1 p-5 inline-block text-sm text-black bg-pastel-100 text-slate-800 transition duration-300 ease-out hover:ease-in hover:bg-black hover:text-white hover:outline-black">
                        "The standout moments are when 3BD's projects are enjoyed by the audience and validated by the market. Seeing all the work and passion the team puts into these projects result in demand for more from fans and brands is a big endorsement for 3BD's vision" 
                        <br /><br />
                        <strong>Chris Pyon</strong><br />Finance Team
                      </div>
                      <div className="outline w-full outline-1 p-5 inline-block text-sm text-black bg-pastel-100 text-slate-800 transition duration-300 ease-out hover:ease-in hover:bg-black hover:text-white hover:outline-black">
                        "Alpha Betas, our animated video game streamer comedy, has been such fun to work on but also a fascinating lesson in the alternatives that exist to traditional distribution. The show is a blend of modern digital audiences and traditional Hollywood talents coming together to pioneer how content is created and viewed in a world where everyone is both a creator and an audience member. Navigating those challenges makes the success all the more impactful, and proves we're doing something that really hasn't been done before not only with the show itself, but the format as well." 
                        <br /><br />
                        <strong>Reed Simmons</strong><br />Content Team
                      </div>
                    </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
          </Parallax>

          <Parallax translateY={[5, -10]}>
          <div className="bg-pastel-100">
            <div className="container mx-auto p-10">
              <hr className="border-black m-0" />
            </div>
          </div>
          </Parallax>
          
          <Parallax translateY={[5, -10]}>
          <div className="bg-pastel-100">
            <div className="container mx-auto">
              <div className="px-10 py-20">
                <h2 className="knockoutHTF67 text-5xl lg:text-6xl uppercase mb-10 tracking-wide text-center">Openings</h2>
                <div className="proximaNova grid grid-cols-1 gap-5 text-center">
                  {jobsData.map(job => {
                  return (job.visibility === true) ? 
                      <a href={job.link} target="_blank" rel="noopener noreferrer" className="external-link-black">{job.title}</a>
                    :
                      null
                  })}
                </div>
              </div>
            </div>
          </div>
          </Parallax>

        </div>

        </ParallaxProvider>
        <Footer />
      </div>
    )
}



export const pageQuery = graphql
`
  query JobPosts {
    webiny {
      listJobs(sort: datePublished_DESC, limit: 100) {
        data {
          title
          visibility
          positionType
          modelId
          id
          entryId
          datePublished
          createdOn
          body
          baseSalary
          link
        }
      }
    }
  }
`
