import React, { Component } from "react"
import { Link } from "gatsby"
import Seo from "../../components/seo"

import Header from "../../components/header"

class DeadRealmPage extends Component {
  
  render() {
    return (
      <div>
        <Header />
        <Seo title="Dead Realm" />
        <div className="container-fluid deadrealm">
          <div className="row">
            <div className="col-12 deadrealmblock ">
              <h2>
                DEAD<br></br>REALM
              </h2>
              <p>Run, hide, survive... or die.</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10 col-md-6 section-deadrealm-one">
              <h3>How do you start a movement in the crowded videogame market?</h3>
              <p>
                With the launch of Dead Realm, our second videogame and first PC title, we continued to prove that not
                only could we move audiences from one platform to another, but we could also monetize them. Dead Realm
                was launched entirely with influencer marketing. We incorporated influencers in the design process of
                the game and allowed them to tell us the game their audiences most needed. When we launched, it was
                immediately clear that we nailed the audience and product loop. To the delight of many fans, Dead Realm
                became Most Popular Game, #1 Horror Game, and #1 Indie Game on Steam in its first week. In less than a
                week, 3BLACKDOT had over 22,000 influencers organically creating Dead Realm content. 90% of fans gave
                Dead Realm a very positive rating out of over 1700+ reviews.
              </p>
            </div>
          </div>
          <div className="row justify-content-center no-gutters">
            <div className="col-12">
              <img
                className="img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/dead-realm.jpg"
                alt=""
              />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10 section-deadrealm-two">
              <h3 className="text-center">
                Dead Realm monetized<br></br>
                influencer audience<br></br>
                with a premium title
              </h3>
              <h2>#1 POPULAR </h2>
              <h3>New Release</h3>
              <h2>#1 INDIE</h2>
              <h3>Early Access Game</h3>
              <h2>#1 HORROR</h2>
              <h3>Game</h3>
              <p className="stats">*First Week Stats on Steam</p>
            </div>
          </div>

          <div className="row">
            <div className="col-12 section-deadrealm-services">
              <img
                className="img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset-Services.png"
                alt=""
              />
              <Link to="/services/game-development/">
                <h4>GAME DEVELOPMENT</h4>
              </Link>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
}

export default DeadRealmPage
