import React, { Component } from "react"
import { Link } from "gatsby"
import Seo from "../../components/seo"

import Header from "../../components/header"

class OrionChildsPlayPage extends Component {
 

  render() {
    return (
      <div>
        <Header />
        <Seo title="Orion Childs Play" />
        <div className="container-fluid orionchildsplay">
          <div className="row">
            <div className="col-12 orionchildsplayblock ">
              <h2>
                ORION<br></br>CHILD'S PLAY
              </h2>
              <p>Chucky is back.</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10 col-md-6 section-orionchildsplay-one">
              <p>
                In anticipation of Orion’s film reboot of Child’s Play, 3BLACKDOT set out to introduce the terrifying
                franchise to a new generation of horror fans. To drum up excitement for the movie, we created custom
                content and experiences to make the nightmare come alive.
              </p>
              <p>
                Introducing the iconic killer doll to a new audience was an exciting opportunity for 3BLACKDOT. We
                decided we needed to make a splash that went beyond digital content, one that would allow a new audience
                to experience the horror of Chucky in real life. Together with Orion, we rebuilt the set of Child’s Play
                for a special RSVP event and invited some of our top influencers. In taking the horror out of the
                digital and placing it in a vivid, new environment, this made for an overall more visceral viewing
                experience.
              </p>
            </div>
          </div>
          <div className="row justify-content-center no-gutters">
            <div className="col-12 col-md-6">
              <img
                className="img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset143.jpg"
                alt=""
              />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10 col-md-6 section-orionchildsplay-one">
              <p>
                Online, by leveraging our extensive influencer channels, we produced viral videos and distributed them
                across our 3BLACKDOT-owned Facebook network that boasts 25M fans of movies, gaming, and culture. Because
                we tailored the experiential element of our campaign to resonate specifically with our influencers’
                audience, this further extended our reach online.
              </p>
              <p>Ultimately, our chilling campaign drove 1.2 million views and 2.5 Instagram impressions.</p>
            </div>
          </div>
          <div className="row justify-content-center no-gutters">
            <div className="col-12 col-md-6">
              <img
                className="img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset133.png"
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset116.jpg"
                alt=""
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 section-orionchildsplay-services">
              <img
                className="img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset-Services.png"
                alt=""
              />
              <Link to="/services/brand-integrations/">
                <h4>BRANDED INTEGRATIONS</h4>
              </Link>
              <Link to="/services/live-events">
                <h4>LIVE EVENTS</h4>
              </Link>
              <Link to="/services/content-production">
                <h4>CONTENT ORIGINAL</h4>
              </Link>
              <Link to="/services/media-distribution">
                <h4>MEDIA DISTRIBUTION</h4>
              </Link>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
}

export default OrionChildsPlayPage
