/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/custom-styles.css";
import Amplify from "aws-amplify";
import awsConfig from "./src/aws-exports";
import "@aws-amplify/ui/dist/style.css";

Amplify.configure(awsConfig);

// Define the TrackingPixel component
// const TrackingPixel = () => {
//   // Using React's useEffect hook to ensure this runs in the client-side
//   React.useEffect(() => {
//     const img = new Image(1, 1);
//     img.src = "https://kl6862m91c.execute-api.us-east-1.amazonaws.com/1/pixel?client=3bd";
//     img.alt = "";
//     img.style.display = "none";
//     // Append the tracking pixel to the body
//     document.body.appendChild(img);
//   }, []);

//   // Since nothing needs to be rendered to the screen, return null
//   return null;
// };

// // Use Gatsby's wrapRootElement Browser API to include the tracking pixel on every page
// export const wrapRootElement = ({ element }) => {
//   return (
//     <>
//       <TrackingPixel />
//       {element}
//     </>
//   );
// };
