import React, { Component } from "react"
import { Link } from "gatsby"
import Seo from "../../components/seo"

import Header from "../../components/header"

class EatBrainsLovePage extends Component {
 
  render() {
    return (
      <div>
        <Header />
        <Seo title="EAT BRAINS LOVE" />
        <div className="container-fluid eatbrainslove">
          <div className="row">
            <div className="col-12 eatbrainsloveblock ">
              <h2>
                EAT
                <br></br>BRAINS<br></br>LOVE
              </h2>
              <p>Undead Friends with Benefits.</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10 col-md-6 section-eatbrainslove-one">
              <p>
                Based on the titular novel, 3BLACKDOT produced this feature film together with Van Toffler’s Gunpowder &
                Sky and Tony DiSanto’s DIGA Studios. Directed by Rodman Flender, Eat Brains Love centers on a high
                school couple who suddenly find that they’ve turned into zombies. As promised by the title, the
                not-so-sweethearts go on to eat the brains of half their senior class and subsequently take a road trip
                in search of a cure. Meanwhile, the government has recruited a teen psychic to chase them down.
              </p>
              <p>
                A hotly anticipated property, Eat Brains Love received rave reviews at ​Screamfest Horror Film Festival​
                2019, and will be released in 2020.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 section-eatbrainslove-services">
              <img
                className="img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset-Services.png"
                alt=""
              />
              <Link to="/services/publishing/">
                <h4>PUBLISHING</h4>
              </Link>
              <Link to="/services/content-production">
                <h4>CONTENT PRODUCTION</h4>
              </Link>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
}

export default EatBrainsLovePage
