import React, { Component } from "react"
import Seo from "../../components/seo"

import Header from "../../components/header"
import Footer from "../../components/footer"

import { Link } from "gatsby"

class amPage extends Component {


  render() {
    let isHeaderBgBlack = false;
    const canonicalUrl = this.props.location.pathname;
    if (canonicalUrl === "/projects/3am/") {
      isHeaderBgBlack = true;
    } 
    return (
      <div>
        <Seo title="3AM" />
        <Header isHeaderBgBlack={isHeaderBgBlack} />
        <div className="bg-yellow-100 proximaNova p-10 lg:px-16 lg:py-20 text-black">
          <div className="my-10">
            <div className="">
            <h2 className="knockoutHTF67 px-1 lg:px-3 inline-block bg-black m-0 text-5xl lg:text-6xl uppercase text-yellow-100 lg:tracking-wide mb-5">3AM</h2>
              <p>Exclusive event with surprise DJs, brand activations, live art, and merchandise.</p>
            </div>
            <div className="col-12 col-md-6 secondblock">
              <p>
                We know youth culture and we start movements. In many ways, 3AM perfectly embodies 3BLACKDOT’s
                positioning at the intersection of culture, gaming, and lifestyle.
              </p>
              <p>
                3AM is a festival where youth gather to experience live music, live art, delicious food, fashion, and of
                course, gaming.{" "}
              </p>
              <p>
                The way we see it, even a fun party is an opportunity for 3BLACKDOT to make good on our values. 3AM has
                featured a host of world-renowned, DJs like Alice in Wonderland, TOKiMONSTA, and Night Bass. This year,
                we introduced Neon Love Project, our philanthropic initiative to shine light on and raise money for
                causes we’re passionate about. We incorporated the fundraiser through a live art auction, donations, and
                custom merchandise, to show how connected our youth audience is to doing good while having an awesome
                time.{" "}
              </p>
              <p>
                3AM gets more exciting with every iteration. This past year lines were around the corner and we welcomed
                over 3000 people to our event, all by word of mouth. It’s only going to get bigger and better. We hope
                to see you next year!{" "}
              </p>
            </div>
          </div>
          <div className="my-10">
            <div className="">
              <img
                className=" img-border img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset57.png"
                alt=""
              />
            </div>
            <div className="">
              <img
                className=" img-border img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset39.png"
                alt=""
              />
            </div>
            <div className="">
              <img
                className=" img-border img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset41.png"
                alt=""
              />
            </div>
            <div className="">
              <img
                className=" img-border img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset40.png"
                alt=""
              />
            </div>
            <div className="">
              <img
                className=" img-border img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset42.png"
                alt=""
              />
            </div>
            <div className="">
              <img
                className=" img-border img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset10.png"
                alt=""
              />
            </div>
            <div className="">
              <h3 className="mb-7 knockoutHTF47 tracking-wide text-4xl lg:text-5xl uppercase mt-0 mb-0 mt-3">Services</h3>
            </div>
            <div className="">
              <Link to="/services/live-events">
                <h4>LIVE EVENTS</h4>
              </Link>
              <Link to="/services/merchandise">
                <h4>MERCHANDISE</h4>
              </Link>
            </div>
          </div>
        </div>
        
        <Footer /> 
      </div>
    )
  }
}

export default amPage
