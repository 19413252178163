import React, { Component } from "react"
import { Link } from "gatsby"
import Seo from "../../components/seo"

import Header from "../../components/header"

class LaurenPage extends Component {
  

  render() {
    return (
      <div>
        <Header />
        <Seo title="LAUREN Z SIDE" />
        <div className="container-fluid lauren">
          <div className="row">
            <div className="col-12 laurenblock ">
              <h2>LAUREN Z SIDE</h2>
              <p>Lauren Z Side has over 2.5 million YouTube subscribers and over 600 million views.</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10 col-md-6 section-lauren-one">
              <p>
                Lauren Z Side was one of our earliest partners and together, we built out a complete line of truly
                unique merchandise.
              </p>
              <p>
                3BLACKDOT wanted to connect with Lauren Z Side’s fanbase and come up with special apparel and
                accessories that would resonate with her community. In a way, the merchandise was simply an extension of
                Lauren’s identity—each product was authentically conceived and designed to extend her brain from the
                screen to a tangible good.
              </p>
              <p>
                The world of Lauren Z Side is galactic: it’s dazzling, steeped in jewel tones, opalescent, and bursting
                with stars. Each product in her merchandise line will catch your eye, from glow-in-the-dark,
                star-studded leggings to holographic fanny packs and pastel silk bombers. One of the most popular items
                was an adorable plushie, modeled after Lauren Z Side’s beloved dog, Dexter—the limited edition plushie
                sold out within hours!
              </p>
              <p>
                With 3BLACKDOT, you’re getting more than just a simple purchase. You’re getting a unique retail
                experience that’s custom-made for your brand.
              </p>
            </div>
          </div>
          <div className="row justify-content-center no-gutters">
            <div className="col-12 col-md-6">
              <img
                className="img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset121.jpg"
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset127.jpg"
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset122.jpg"
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset126.jpg"
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset125.jpg"
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset124.jpg"
                alt=""
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 section-lauren-services">
              <img
                className="img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset-Services.png"
                alt=""
              />
              <Link to="/services/merchandise/">
                <h4>MERCHANDISE</h4>
              </Link>
            </div>
          </div>
        </div>
         
      </div>
    )
  }
}

export default LaurenPage
