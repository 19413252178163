import React, { Component } from "react"
import { Link } from "gatsby"
import Seo from "../../components/seo"

import Header from "../../components/header"

class AMillionPage extends Component {
  
  render() {
    return (
      <div>
        <Header />
        <Seo title="A MILLION LITTLE PIECES" />
        <div className="container-fluid amillion">
          <div className="row">
            <div className="col-12 amillionblock ">
              <h2>
                A MILLION
                <br></br>LITTLE PIECES
              </h2>
              <p>In theaters December 6.</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10 col-md-6 section-amillion-one">
              <p>
                Based on James Frey’s massive New York Times’​ Bestselling Novel of the same name, 3BlackDot is proud to
                have produced this feature film together with Makeready Studios. Director Sam Taylor-Johnson (​Fifty
                Shades of Grey)​ and actor Aaron Taylor-Johnson (​Nocturnal Animals)​ bring this film adaptation to
                life. Like the groundbreaking novel, A Million Little Pieces is a frank and moving depiction of drug
                addiction. In the feature film, we’re witness to the destruction and reconstruction of a single life as
                the main character struggles with recovery and sobriety.
              </p>
              <p>The film hits theaters stateside on December 6, 2019, distributed by Momentum.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 section-amillion-services">
              <img
                className="img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset-Services.png"
                alt=""
              />
              <Link to="/services/publishing/">
                <h4>PUBLISHING</h4>
              </Link>
              <Link to="/services/content-production">
                <h4>CONTENT PRODUCTION</h4>
              </Link>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
}

export default AMillionPage
