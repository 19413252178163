import React, { Component } from "react"
import { Link } from "gatsby"
import Seo from "../../components/seo"

import Header from "../../components/header"

class MediaDistributionPage extends Component {

  render() {
    return (
      <div>
        <Header />
        <Seo title="Media Distribution" />
        <div className="container-fluid mediadistribution">
          <div className="row">
            <div className="col-12 mediadistributionblock ">
              <h2>
                MEDIA<br></br>DISTRIBUTION
              </h2>
              <p>
                Access to our exclusive inventory of<br></br>passionate, active, engaged viewers.
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10 col-md-3 section-mediadistribution-one">
              <h3>Reach the Masses</h3>
              <p>
                What could you do with 500 million ad impressions? Introduce the world to your brand? Launch your most
                successful product to date? Revolutionize an industry?
              </p>
              <p>
                Our multi-channel network JETPAK generates hundreds of millions of views on a monthly basis—that’s
                enough ad inventory to transform your business overnight.
              </p>
              <h3>Target Distribution</h3>
              <p>
                Put your message in front of those ready and willing to hear it. We’ll hand pick the audiences within
                our network that match to your brand. Then we’ll measure the results. Whatever the KPI, we track and
                report how your ads perform so you can scale up, scale down, or optimize accordingly.
              </p>
            </div>
            <div className="col-10 offset-md-1 col-md-3 section-mediadistribution-two">
              <h3>Get Started</h3>
              <p>
                Inventory is limited and the schedule fills fast. Get in touch so we can start delivering your message
                to audiences who will obsess over your brand, buy your product, and remain loyal for life.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 section-mediadistribution-related">
              <h4>RELATED PROJECTS</h4>
            </div>
          </div>
          <div className="row section-mediadistribution-related-projects">
            <div className="col-6 col-md-3 project littlepieces">
              <Link to="/projects/a-million-little-pieces">
                <h4>
                  A MILLION<br></br>LITTLE PIECES
                </h4>
              </Link>
            </div>
            <div className="col-6 col-md-3 project eatbrainlove">
              <Link to="/projects/eat-brains-love">
                <h4>
                  EAT<br></br>BRAINS<br></br>LOVE
                </h4>
              </Link>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
}

export default MediaDistributionPage
