import React, { Component } from "react"
import Seo from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"

class ServicesPage extends Component {
 
  render() {
    return (
      <div>
        <Header />
        <Seo title="Services" />

        <div className="bg-black">
          <div className="proximaNova container font-normal mx-auto text-pastel-100 pt-20 lg:px-16 lg:pt-28 text-center lg:pb-0">
         
          <div className="container text-sm p-10 mx-auto">
            <h1 className="knockoutHTF47 mb-0 text-center text-5xl lg:text-6xl uppercase tracking-wide mb-5">SERVICES</h1>
                <p className="w-full mx-auto max-w-screen-md">
                We're helping individuals and organizations alike produce genuine brand experiences that exist within
                and shape youth culture.
                <br />
                <br />
                Sure, engagement is cool. 3BLACKDOT delivers movements. Because a movement is how you'll go beyond what
                they watch and become what they wear, what they share, what they speak, and what they think.
              </p>
          </div>

        <div className="services grid grid-cols-1 gap-5 lg:gap-5">
          <div className="container">
          
          <div className="text-sm grid grid-cols-1 p-10 lg:grid-cols-4 gap-10">
            <div className="col-12 col-md-6 services-container">
              <div className="servicesblock ipcreation">
                  <h2>Brand Integrations</h2>
              </div>
              <p>
                We are influence. Let us connect you with voices who will amplify your brand’s value to their millions
                of followers. We’ll start the relationship, you’ll discover dedicated, lifelong customers.
              </p>
            </div>
            <div className="col-12 col-md-6 services-container">
              <div className="servicesblock mediadistribution">
                  <h2>Media Distribution</h2>
              </div>
              <p>
                What would 500 million ad impressions do for your brand? Put your message in front of those ready and
                willing to hear it. Get access to our exclusive inventory of passionate, active, engaged viewers.
              </p>
            </div>
            <div className="col-12 col-md-6 services-container">
              <div className="servicesblock merchandise">
                  <h2>Merchandise</h2>
              </div>
              <p>
                Physical products create a tangible link between your content and the real world, a link that builds
                lifetime affinity. We’ll market, package, and ship that experience right to your customer’s door.
              </p>
            </div>
            <div className="col-12 col-md-6 services-container">
              <div className="servicesblock production">
                  <h2>Content Production</h2>
              </div>
              <p>
                Content Production Creative challenges are what get us out of bed every morning. And the worlds we
                create translate to film, television, gaming, merchandise, literature and more. We’re building lasting
                IP, so come create with us.
              </p>
            </div>
            <div className="col-12 col-md-6 services-container">
              <div className="servicesblock digitalstudio">
                  <h2>Digital Studio</h2>
              </div>
              <p>
                Film here: live streaming, mixed reality, magazine shows, green screening, or scripted content.
                3BLACKDOT’s Digital Studio is a conveniently located LA recording space open to anyone.
              </p>
            </div>
            <div className="col-12 col-md-6 services-container">
              <div className="servicesblock liveevents">
                  <h2>Live Events</h2>
              </div>
              <p>
                Experiences go beyond clicks or likes, engaging your audience with emotive, spiritual expression that
                exists in a physical space.
              </p>
              <p>
                Live art creation, exciting performances, world-class catering—just some of what make our events
                life-changing experiences.
              </p>
            </div>
            <div className="col-12 col-md-6 services-container">
              <div className="servicesblock gamedevelopment">
                  <h2>Game Development</h2>
              </div>
              <p>
                Games should be as fun to watch as they are to play. That’s why every project we support designs for the
                viewer as much as the player.
              </p>
              <p>Let’s build communities together.</p>
            </div>
            <div className="col-12 col-md-6 services-container">
              <div className="servicesblock publishing">
                  <h2>Publishing</h2>
              </div>
              <p>
                We’ll make the world care about what you do, because even the best get ignored without a conduit to the
                masses.
              </p>
              <p>
                We own the audience, the distribution, and the creative. Take advantage of our network to give your
                project its best chance at success.
              </p>
            </div>
          </div>
        </div>
        </div>

        
        </div>
        </div>


        
        <Footer />
      </div>
    )
  }
}

export default ServicesPage
