import React, { Component } from "react"
import { Link, graphql } from "gatsby"
import InstagramEmbed from "react-instagram-embed"
import Img from "gatsby-image"
import Seo from "../../components/seo"

import Header from "../../components/header"

export const data = graphql`
  query {
    betaward: file(relativePath: { eq: "bet-awards.png" }) {
      childImageSharp {
        id
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

class QueenAndSlimPage extends Component {
  state = {
    data: this.props.data,
  }
  render() {
    return (
      <div>
        <Header />
        <Seo title="QUEEN & SLIM" />
        <div className="container-fluid queenandslim">
          <div className="row">
            <div className="col-12 queenandslimblock ">
              <h2>
                QUEEN<br></br>&<br></br>SLIM
              </h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10 col-md-6 section-queenandslim-one">
              <p>
                Here at 3BLACKDOT, we want to make exciting work with today’s
                boldest creators and Queen & Slim is our keystone. 3BLACKDOT is
                proud to have created, produced, and co-financed Queen & Slim, a
                groundbreaking feature film that demonstrates our belief and
                willingness to take risks and bet on unique voices that we truly
                believe in.
              </p>
              <p>
                Queen & Slim is from the minds of Emmy award-winning writer Lena
                Waithe, visionary director Melina Matsoukas, and Oscar-nominated
                actor Daniel Kaluuya. We were excited to partner with Lena
                because we align with her fresh, unique perspective. Lena
                doesn’t just write; she aims to start a movement. As an original
                and prominent voice of her generation, Waithe stands apart from
                the rest due to her talent, as well for having a direct
                connection to fans, atypical for most Hollywood writers.
              </p>
              <p>
                Queen & Slim is primed to ride the zeitgeist of compelling
                storytelling. Arriving on the scene like a modern Bonnie &
                Clyde, the story doesn’t shy away from hard truths of racial
                injustice and institutional violence in today’s charged
                political climate. Always unapologetic, we at 3BLACKDOT are
                excited to empower new voices and perspectives, as well as
                participate in one of the most relevant conversations of today.
                The message of Queen & Slim is one we’re proud to support, as it
                aligns firmly with our core values and beliefs.
              </p>
              <p>
                Timely, topical, and charged, Queen & Slim should resonate
                deeply with today’s audience. Queen & Slim hits theaters
                worldwide on November 27, 2019 and was produced in collaboration
                with Makeready Studios and Universal Pictures.
              </p>
              <InstagramEmbed
                url="https://www.instagram.com/p/CCAXmnCnlzA/"
                maxWidth={550}
                hideCaption={true}
                containerTagName="blockquote"
                protocol=""
                injectScript
                onLoading={() => {}}
                onSuccess={() => {}}
                onAfterRender={() => {}}
                onFailure={() => {}}
              />
            </div>
          </div>
          <div className="row justify-content-center no-gutters">
            <div className="col-12">
              <br></br>
              <Img
                className="mx-auto d-block img-fluid "
                key={this.state.data.betaward.childImageSharp.id}
                fixed={this.state.data.betaward.childImageSharp.fixed}
              />
              <br></br>
              <br></br>
              <br></br>
              <img
                className="img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/queenandslim-free.gif"
                alt=""
              />
              <br></br>
              <br></br>
              <br></br>
            </div>
          </div>

          <div className="row">
            <div className="col-12 section-queenandslim-services">
              <img
                className="img-fluid mx-auto d-block"
                src="https://3bd-misc.s3-us-west-2.amazonaws.com/site/Asset-Services.png"
                alt=""
              />
              <Link to="/services/content-production">
                <h4>CONTENT PRODUCTION</h4>
              </Link>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
}

export default QueenAndSlimPage
